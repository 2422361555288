import { useState } from 'react';
import { Carousel, Modal } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import FormButton from '../FormNavButton';

interface PreviewProps {
  spec: any,
}

const Preview: React.FC<PreviewProps> = ({ spec }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [indexPreviewCarousel, setIndexPreviewCarousel] = useState(0);

  const handleSelectCarousel = (selectedIndex: number) => {
    setIndexPreviewCarousel(selectedIndex);
  };

  const openPreviewModal = () => {
    setShowPreviewModal(true);
  };
  return (
    <>
      <Modal
        size="lg"
        show={showPreviewModal}
        onHide={() => setShowPreviewModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
        Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pa-modal-preview-body">
          {spec.content?.previewDescription && <p>
            {spec.content?.previewDescription}
          </p>}
          {
            spec.content?.previewUrls?.length > 0
          && <Carousel
            activeIndex={indexPreviewCarousel}
            onSelect={handleSelectCarousel}
            variant="dark"
            interval={null}
            indicators={spec.content.previewUrls?.length >= 2}
            controls={spec.content.previewUrls?.length >= 2}
          >
            {
              spec.content.previewUrls.map((preview: any, key: any) => (
                <Carousel.Item key={key}>
                  <img
                    className="d-block w-100"
                    src={preview}
                    alt="First slide"
                  />
                </Carousel.Item>
              ))
            }
          </Carousel>
          }
        </Modal.Body>
        <Modal.Footer>
          <FormButton
            onClick={() => setShowPreviewModal(false)}
            style={{ background: 'transparent' }}
            invert
          >
        Close
          </FormButton>
        </Modal.Footer>
      </Modal>
      {spec.content?.previewUrls?.length > 0
    && <div
      className="pa-preview-button d--f ai--c"
      onClick={() => openPreviewModal()}
      style={{ flexShrink: 0 }}
    >show me <FaEye /> </div>}
    </>
  );
};
export default Preview;
