import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import {
  FaCheckCircle, FaExclamationCircle, FaLaptopCode,
} from 'react-icons/fa';
import FormButton from '../../components/FormNavButton';
import Spacer from '../../components/Spacer';
import { db } from '../../firebaseConfig';
import { JourneyStatus } from '../../model/journeyStatus';
import JourneyStepsStatus from '../../model/JourneyStepsStatus';
import companyManagmentService from '../../services/CompanyManagementService';
import journeyManagementService from '../../services/JourneyManagementService';
import notificationManagementService from '../../services/NotificationManagementService';
import StepNavigationContext from '../../services/StepNavigationContext';
import { useAppSelector } from '../../store/Hooks';

type JourneyCompletedProps = {
  spec: any,
}

const JourneyCompleted: React.FC<JourneyCompletedProps> = () => {
  const journey = useAppSelector((state) => state.journey);
  const user = useAppSelector((state) => state.user);
  const [isCompleted, setIsCompleted] = useState(false);
  const [servicesList, setServicesList] = useState([]) as any[];
  const [status, setStatus] = useState('');
  const [approveAllError, setApproveAllError] = useState('');
  const { values, submitForm, setFieldValue }
  : {values:any, submitForm:any, setFieldValue:any} = useFormikContext();
  const { previousStep } = useContext(StepNavigationContext);

  useEffect(() => {
    if (values.steps[journey.currentStep]
      && values.steps[journey.currentStep].status
      && values.steps[journey.currentStep].status === JourneyStepsStatus.UNSEEN) {
      changeStepStatus(JourneyStepsStatus.VISITED);
    }
  }, []);

  const changeStepStatus = (s:string) => {
    setFieldValue(
      `steps[${journey.currentStep}].status`,
      s,
    );
  };

  useEffect(() => {
    const unsubscribe = db.collection('Companies').doc(user.company).onSnapshot((doc) => {
      const data = doc.data();
      if (data) {
        setStatus(data.formStatus);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    let completed = true;
    if (values && values.steps) {
      values.steps.forEach((step:any) => {
        if (step.status && step.status !== JourneyStepsStatus.APPROVED) {
          completed = false;
        }
      });
    }
    if (completed && status !== '' && status !== JourneyStatus.CONTENT_SUBMITTED && status !== JourneyStatus.LIVE) {
      companyManagmentService.changeStatus(user.company, JourneyStatus.CONTENT_SUBMITTED);
    }
    setIsCompleted(completed);
  }, []);

  useEffect(() => {
    companyManagmentService.getAllServices(journey.id, user.company)
      .then((response: any) => {
        setServicesList(response);
      });
  }, []);

  const previousStepChild = async () => {
    previousStep();
    submitForm();
  };

  const approveAll = () => {
    let wasError = false;
    for (let i = 0; i < values.steps.length; i += 1) {
      if (values.steps[i].status !== JourneyStepsStatus.ERROR) {
        setFieldValue(
          `steps[${i}].status`,
          JourneyStepsStatus.APPROVED,
        );
      } else {
        wasError = true;
      }
    }
    journeyManagementService.saveJourney(values, user.company, journey.id);
    if (!wasError) {
      companyManagmentService.changeStatus(user.company, JourneyStatus.CONTENT_SUBMITTED);
      setIsCompleted(true);
      setApproveAllError('');
    } else {
      setApproveAllError('Some sections have errors. Please go back and fix the errors before proceeding (the steps with errors are marked in red).');
    }
  };

  const goLive = () => {
    companyManagmentService.changeStatus(user.company, JourneyStatus.READY_FOR_TEMP_SITE);
    companyManagmentService.addLog(user.company, user.name, 'Clicked the go live button in form');
  };

  const backToEdit = () => {
    companyManagmentService.changeStatus(user.company, JourneyStatus.FILLING_EFORM);
    notificationManagementService.sendStatusChangeNotification(user.company, 'went_back_to_editing');
    companyManagmentService.addLog(user.company, user.name, 'Went back to editing after pressing go live');
    previousStepChild();
  };

  return (
    <>
      <div className="pa-fullscreen-container completed has-journey-backdrop-graphics pa-scroll-container">
        <div className="pa-fullscreen-content-wrapper">
          {(!isCompleted && status !== JourneyStatus.READY_FOR_TEMP_SITE)
          && <><div>
            <div className='ta--c'>
              <FaExclamationCircle style={{
                color: 'var(--warning)',
                fontSize: 80,
              }} />
              <Spacer />
              <h2>Your website is almost ready to go!</h2>
            </div>

            <Spacer />
            <p className='ta--c'>
              Before we build website, let's make sure you have entered all your content.
              It looks like some sections of this form are not approved or have errors.
              Please go back and approve all sections before we start building your website.
            </p>
          </div>
          <div className='pa-form-step-nav-container' style={{ justifyContent: 'center' }}>
            <FormButton
              onClick={() => { previousStepChild(); }}
              back
              style={{
                marginRight: 10,
              }}
            />
            <FormButton
              onClick={() => approveAll()}
              style={{
                marginRight: 10,
                color: 'var(--primary)',
                background: 'var(--light)',
              }}
            >
            Approve all
            </FormButton>
          </div>
          {approveAllError && <div className="pa-input-error" style={{
            marginTop: 20, background: 'rgba(255,255,255,0.8)', borderRadius: 4, padding: 4,
          }}>{approveAllError}</div>}
          </>
          }
          {(isCompleted
          && status !== JourneyStatus.READY_FOR_TEMP_SITE
          && status !== JourneyStatus.TEMP_SITE_REVIEW
          && status !== JourneyStatus.WAITING_DNS)
          && <>
            <div>
              <div className='ta--c'>
                <FaCheckCircle style={{
                  color: 'var(--light)',
                  fontSize: 80,
                }} />
                <Spacer />
                <h2>Your website is almost ready to go!</h2>
              </div>
              <Spacer />
              <p>
              Your website will allow your customers to book your services easily and directly
              online. To do so, you will need to have a booking page associated with
              every service you have entered in this form. Please go to the "Booking Page" tab
              in the left sidebar and make sure you create a booking page for each of the
              following services:
              </p>
              <ul>
                {servicesList.map((service:{value:string, label:string}, key:number) => (
                  <li key={key} style={{ fontSize: 20, marginBottom: 10 }}>
                    {service.label}
                  </li>
                ))}
              </ul>
              <p>
              Once you have created all of your booking pages,
              please press the "Let's Go Live" button bellow.
              </p>
              <p style={{ fontStyle: 'italic' }}>
              Please contact us if you need any help creating your booking pages.
              </p>
            </div>
            <div className='pa-form-step-nav-container' style={{ justifyContent: 'center' }}>
              <FormButton
                onClick={() => { backToEdit(); }}
                style={{
                  marginRight: 10,
                  color: 'var(--light)',
                  background: 'transparent',
                  fontSize: 12,
                }}
              >
                Back to editing
              </FormButton>
              <FormButton
                onClick={() => goLive()}
                style={{
                  marginRight: 10,
                  color: 'var(--primary)',
                  background: 'var(--light)',
                }}
              >
                Let's Go Live
              </FormButton>
            </div>
          </>
          }
          {(isCompleted && status === JourneyStatus.READY_FOR_TEMP_SITE)
           && <>
             <div>
               <div className='ta--c'>
                 <FaLaptopCode style={{
                   color: 'var(--light)',
                   fontSize: 80,
                 }} />
                 <Spacer />
                 <h2>We are currently building your temporary website!</h2>
               </div>
               <Spacer />
               <p>
               We are currently building a temporary website with the content you have submitted
               in this form. Once it is ready, we will ask you to review it before we go live.
               </p>
             </div>
             <div className='pa-form-step-nav-container' style={{ justifyContent: 'center' }}>
               <FormButton
                 onClick={() => { backToEdit(); }}
                 style={{
                   marginRight: 10,
                   color: 'var(--light)',
                   background: 'transparent',
                   fontSize: 12,
                 }}
               >
                Back to editing
               </FormButton>
             </div>
           </>
          }
          {(isCompleted
          && (status === JourneyStatus.TEMP_SITE_REVIEW || status === JourneyStatus.WAITING_DNS))
           && <>
             <div>
               <div className='ta--c'>
                 <FaLaptopCode style={{
                   color: 'var(--light)',
                   fontSize: 80,
                 }} />
                 <Spacer />
                 <h2>Your temporary website is under review.</h2>
               </div>
               <Spacer />
               <p>
               We are coordinating with you via email to make any last minute changes
               to your temporary website before it goes live. Once it goes live, you can find your
               website credentials here.
               </p>
             </div>
           </>
          }
        </div>
      </div>
      {/* <Debug /> */}
    </>
  );
};
export default JourneyCompleted;
