import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Text from '../../components/formComponents/Text';
import './Steps.css';
import FormButton from '../../components/FormNavButton';
import Checkbox from '../../components/formComponents/Checkbox';
import SingleImage from '../../components/formComponents/SingleImage';
import Repeater from '../../components/formComponents/Repeater';
import Select from '../../components/formComponents/Select';
import Textarea from '../../components/formComponents/Textarea';
import ColorPicker from '../../components/formComponents/ColorPicker';
import Preview from '../../components/formComponents/Preview';
import FormTimeline from '../../components/formComponents/FormTimeline';
import { useAppSelector } from '../../store/Hooks';
import AddressField from '../../components/formComponents/AddressField';
import JourneyStepsStatus from '../../model/JourneyStepsStatus';
import journeyManagementService from '../../services/JourneyManagementService';
import StepNavigationContext from '../../services/StepNavigationContext';

interface StandardFormProps {
  spec: any,
}

const StandardForm: React.FC<StandardFormProps> = ({
  spec,
}) => {
  const user = useAppSelector((state) => state.user);
  const journey = useAppSelector((state) => state.journey);
  const {
    values, errors, submitForm, setFieldValue, validateForm,
  }
  : {
    values:any, errors:any, submitForm:any, validateForm:any, setFieldValue:any
  } = useFormikContext();
  const [stepError, setStepError] = useState('');
  const { nextStep, previousStep } = useContext(StepNavigationContext);

  useEffect(() => {
    validateForm();
    if (values.steps
      && values.steps[journey.currentStep]
      && values.steps[journey.currentStep].status
      && values.steps[journey.currentStep].status === JourneyStepsStatus.UNSEEN) {
      changeStepStatus(JourneyStepsStatus.VISITED);
    }
  }, []);

  useEffect(() => {
    if (errors && errors.steps && errors.steps[journey.currentStep]) {
      changeStepStatus(JourneyStepsStatus.ERROR);
      setStepError('One or more field contains an error. Please fix it before moving forward.');
    } else {
      if (values.steps
        && values.steps[journey.currentStep]
        && values.steps[journey.currentStep].status
        && values.steps[journey.currentStep].status !== JourneyStepsStatus.APPROVED) {
        changeStepStatus(JourneyStepsStatus.VISITED);
      }
      setStepError('');
    }
  }, [errors]);

  const previousStepChild = async () => {
    previousStep();
    submitForm();
  };

  const nextStepChild = () => {
    if (errors && errors.steps && errors.steps[journey.currentStep]) {
      setStepError('One or more field contains an error or is empty. Please fix it before moving forward.');
    } else {
      nextStep();
      submitForm();
    }
  };

  useEffect(() => {
    journeyManagementService.saveJourney(values, user.company, journey.id);
  }, [stepError]);

  const changeStepStatus = async (status:string) => {
    setFieldValue(
      `steps[${journey.currentStep}].status`,
      status,
    );
  };

  return (
    <div className="pa-scroll-container">
      <div className="standard-form-container has-journey-backdrop-graphics light">
        <div className="pa-form-content-area">
          <div className='pa-container'>
            <div className="d--f ai--c">
              <h1 className="standard-form-title c--primary">{spec.title}</h1>
              <Preview spec={spec} />
            </div>
            <div className="standard-form-wrapper pa-scroll-container bg--white">
              {spec.content && spec.content.description
              && <p className="pa-form-step-description">{spec.content.description}</p>}
              <div className='pa-standard-form-fields-wrapper'>
                {spec.fields.map((field: any, fieldIndex: any) => {
                  if (!field.hideField) {
                    switch (field.type) {
                      case 'text':
                        return (
                          <Text
                            key={fieldIndex}
                            name={`steps[${journey.currentStep}].fields[${fieldIndex}].${field.name}`}
                            type="text"
                            label={field.label}
                            tooltip={field.tooltip}
                            fieldValidation={field.validation}
                          />
                        );
                      case 'textarea':
                        return (
                          <Textarea
                            key={fieldIndex}
                            name={`steps[${journey.currentStep}].fields[${fieldIndex}].${field.name}`}
                            label={field.label}
                            big={!!field.big}
                          />
                        );
                      case 'checkbox':
                        return (
                          <Checkbox
                            key={fieldIndex}
                            name={`steps[${journey.currentStep}].fields[${fieldIndex}].${field.name}`}
                            type="checkbox"
                            label={field.label}
                            fullWidth={field.fullWidth}
                          />
                        );
                      case 'select':
                        return (
                          <Select
                            key={fieldIndex}
                            name={`steps[${journey.currentStep}].fields[${fieldIndex}].${field.name}`}
                            label={field.label}
                            options={field.optionsType}
                          />
                        );
                      case 'singleImage':
                        return (
                          <SingleImage
                            key={fieldIndex}
                            name={`steps[${journey.currentStep}].fields[${fieldIndex}].${field.name}`}
                            label={field.label}
                            imageType={field.imageType}
                            tooltip={field.tooltip}
                          />
                        );
                      case 'repeater':
                        return (
                          <Repeater
                            key={fieldIndex}
                            name={`steps[${journey.currentStep}].fields[${fieldIndex}].${field.name}`}
                            type={field.repeaterType}
                            spec={field}
                            tooltip={field.tooltip}
                            fieldValidation={field.validation}
                          />
                        );
                      case 'colorPicker':
                        return (
                          <ColorPicker
                            key={fieldIndex}
                            name={`steps[${journey.currentStep}].fields[${fieldIndex}].${field.name}`}
                            label={field.label}
                            tooltip={field.tooltip}
                          />
                        );
                      case 'address':
                        return (
                          <AddressField
                            key={fieldIndex}
                            name={`steps[${journey.currentStep}].fields[${fieldIndex}].${field.name}`}
                            label={field.label} />
                        );
                      default:
                        return (<div key={fieldIndex}></div>);
                    }
                  } else {
                    return (<div key={fieldIndex}></div>);
                  }
                })}
              </div>
            </div>
            <div className='pa-form-step-nav-container'>
              <FormButton
                onClick={() => { previousStepChild(); }}
                back
                style={{
                  marginRight: 10,
                }}
              />
              <FormButton
                onClick={() => { nextStepChild(); }}
                title='Save & Next'
                next
                disabled={!!stepError}
              />
            </div>
            {stepError && <div className='pa-form-step-error'>{stepError}</div>}
          </div>
        </div>
        <div className="pa-container">
          <FormTimeline />
        </div>
      </div>
    </div>
  );
};
export default StandardForm;
