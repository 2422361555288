import { useFormikContext } from 'formik';
import { useContext, useEffect } from 'react';
import linkIcon from '../../assets/img/external-link-regular.svg';
import Spacer from '../../components/Spacer';
import FormButton from '../../components/FormNavButton';
import JourneyStepsStatus from '../../model/JourneyStepsStatus';
import { useAppSelector } from '../../store/Hooks';
import StepNavigationContext from '../../services/StepNavigationContext';

interface SplitTextSplashscreenProps {
  spec: any,
}

const SplitTextSplashscreen: React.FC<SplitTextSplashscreenProps> = ({
  spec,
}) => {
  const { values, submitForm, setFieldValue }
  : {values:any, submitForm:any, setFieldValue:any} = useFormikContext();
  const journey = useAppSelector((state) => state.journey);
  const { nextStep } = useContext(StepNavigationContext);

  useEffect(() => {
    if (values.steps[journey.currentStep]
      && values.steps[journey.currentStep].status
      && values.steps[journey.currentStep].status === JourneyStepsStatus.UNSEEN) {
      changeStepStatus(JourneyStepsStatus.VISITED);
    }
  }, []);

  const nextStepChild = () => {
    nextStep();
    submitForm();
  };

  const changeStepStatus = (status:string) => {
    setFieldValue(
      `steps[${journey.currentStep}].status`,
      status,
    );
  };

  return (
    <div className="pa-splitscreen-container text pa-scroll-container">
      <div className="pa-splitscreen-col jc--c fd--c ">
        <img className="pa-splitscreen-img" src={spec.content.leftCol.imageUrl} />
        <a target="_blank" href={spec.content.leftCol.footnoteUrl} className="pa-splitscreen-footnote c--primary">
          {spec.content.leftCol.footnoteText}
          <div className="pa-footnote-icon">
            <img src={linkIcon} />
          </div>
        </a>
      </div>
      <div className="pa-splitscreen-col jc--c fd--c bg--primary c--white has-journey-backdrop-graphics">
        <h1 className="pa-splitscreen-title" style={{ marginBottom: 40 }}>{spec.content.rightCol.title}</h1>
        <div className="pa-splitscreen-description" dangerouslySetInnerHTML={{ __html: spec.content.rightCol.description }} />
        <Spacer />
        <FormButton
          onClick={() => { nextStepChild(); }}
          title={spec.content.rightCol.btnText}
          invert
          next
        />
        <div className="pa-splitscreen-footnote fwe--light ft-italic">
          <p className="pa-footnote" style={{ marginTop: 40 }}>{spec.content.rightCol.footnoteText}</p>
        </div>
      </div>
    </div >
  );
};
export default SplitTextSplashscreen;
