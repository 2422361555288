/* eslint-disable no-loop-func */
/* eslint-disable class-methods-use-this */
import { db, firebase, companiesStorage } from '../firebaseConfig';
import ImageType from '../model/Image';
import JourneyImagesLocation from '../model/ImageType';
import { JourneyStatus } from '../model/journeyStatus';
import JourneyStepsStatus from '../model/JourneyStepsStatus';
import { mapThemesToString, mapSectorsToString, isEmptyObj } from './utilities';

class JourneyManagementService {
  // TODO : better error handling
  async initializeJourney(companyId: string, themeId: string, sectorId: number) {
    const sector = mapSectorsToString(sectorId);
    const themeStringSplit = mapThemesToString(themeId).split('-');
    const theme = themeStringSplit[0];
    const variation = themeStringSplit[1];
    let journeySpecsDoc;
    let journeyDataDoc;
    if (sector === 'general' || theme === 'general') {
      journeySpecsDoc = await db.collection('DefaultJourneySpecs')
        .doc('general').get();
      journeyDataDoc = await db.collection('DefaultJourneyData')
        .doc('general').get();
    } else {
      journeySpecsDoc = await db.collection('DefaultJourneySpecs')
        .doc(`${theme}-${variation}-${sector}`).get();
      journeyDataDoc = await db.collection('DefaultJourneyData')
        .doc(`${sector}-${variation}`).get();
    }
    const journeySpecsData = journeySpecsDoc.data();
    const journeyData = journeyDataDoc.data();
    if (journeyData && journeySpecsData) {
      // check if objects are symmetric
      if (journeyData.steps.length === journeySpecsData.steps.length) {
        journeySpecsData.steps.forEach((step:any, i: number) => {
          journeyData.steps[i] = {
            ...journeyData.steps[i],
            status: (
              step.type === 'splitStepSplashscreen'
              || step.type === 'tableOfContents'
              || step.type === 'fullSplashscreen'
              || step.type === 'splitTextSplashscreen'
              || step.type === 'journeyCompleted'
            ) ? JourneyStepsStatus.APPROVED : JourneyStepsStatus.UNSEEN,
            id: (step.id) ? step.id : '',
            major: (step.major) ? step.major : '',
            minor: (step.minor) ? step.minor : '',
          };
        });

        const docRef = await db.collection('Companies').doc(companyId).collection('Journeys').add({
          formSpecsId: journeySpecsDoc.id,
        });

        const companyDocRef = db.collection('Companies').doc(companyId);
        const companyDocObj = await companyDocRef.get();
        const companyDocData = companyDocObj.data();

        if (companyDocData) {
          journeyData.settings.companyName = companyDocData.name;
          journeyData.settings.theme = (sector === 'general') ? 'accelerator' : theme;
        }

        docRef.update({
          id: docRef.id,
          data: journeyData,
          specVersion: (journeySpecsData.version) ? journeySpecsData.version : '',
          currentStep: 0,
        });

        await companyDocRef.update({
          activeFormId: docRef.id,
          formStatus: JourneyStatus.NOT_STARTED,
        });
        return docRef.id;
      }
      console.error('Data and spec objects are not symmetric');
    } else {
      console.error('Data or spec is undefined');
    }
    return null;
  }

  getJourneySpecTotalMajorSteps(journeySpec: any) : number {
    let major = -1;
    let numMajors = 0;
    journeySpec.steps.forEach((e:any) => {
      if (e.major !== major) {
        major = e.major;
        numMajors += 1;
      }
    });
    return numMajors;
  }

  nextStep(companyId: string) {
    this.updateStepCounter(companyId, 1);
  }

  previousStep(companyId: string) {
    this.updateStepCounter(companyId, -1);
  }

  updateStepCounter(companyId: string, value: number) {
    db.collection('Companies').doc(companyId).get().then((doc) => {
      const data = doc.data();
      if (data) {
        doc.ref.collection('Journeys')
          .doc(data.activeFormId)
          .update({
            currentStep: firebase.firestore.FieldValue.increment(value),
          });
      }
    });
  }

  goToSpecificStep(companyId: string, stepNumber: number) {
    db.collection('Companies').doc(companyId).get().then((doc) => {
      const data = doc.data();
      if (data) {
        doc.ref.collection('Journeys')
          .doc(data.activeFormId)
          .update({
            currentStep: stepNumber,
          });
      }
    });
  }

  async saveJourney(data: any, companyId: string, journeyId: string) {
    try {
      if (data && !isEmptyObj(data)) {
        await db.collection('Companies').doc(companyId).collection('Journeys').doc(journeyId)
          .set({
            data,
          }, { merge: true });
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  async uploadImage(image: ImageType) {
    const filePromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image.file);
      reader.onloadend = ((event) => {
        if (event.target && event.target.result) resolve(event.target.result);
        else reject(new Error('Error loading file'));
      });
    });

    const imageData = await filePromise as string;

    const imagePromise = new Promise((resolve) => {
      const imageObj = new Image();
      imageObj.src = imageData;
      imageObj.onload = () => resolve(imageObj);
    });

    const imageObj = await imagePromise as HTMLImageElement;
    if (imageObj.width > 2500 || imageObj.height > 2500) {
      console.log('Image dimensions are too big');
      throw new Error('Image dimensions are too big');
    }

    const storageRef = companiesStorage.ref();
    if (
      image.imageType === JourneyImagesLocation.LOGO
              || image.imageType === JourneyImagesLocation.HOMEPAGE
              || image.imageType === JourneyImagesLocation.SERVICE_MAIN_IMAGE
    ) {
      await this.cleanImagesFromDir(image.location);
    }

    const dirRef = storageRef.child(`${image.location}`);
    const dirImages = await dirRef.listAll();

    const imgExtension = image.file.name.match(/\.[^/\\.]+$/);
    if (!imgExtension) {
      throw new Error('No file extension');
    }

    const originalName = image.file.name.replace(/\.[^/\\.]+$/, '');
    let counter = 1;

    let newFileName: any = image.file.name.replace(/\.[^/\\.]+$/, '');

    let fileNameIsUnique = await this.fileNameIsUnique(newFileName, dirImages);
    while (!fileNameIsUnique) {
      newFileName = `${originalName}(${counter})`;
      counter += 1;
      // eslint-disable-next-line no-await-in-loop
      fileNameIsUnique = await this.fileNameIsUnique(newFileName, dirImages);
    }

    newFileName += imgExtension[0];

    const imageRef = storageRef.child(`${image.location}/${newFileName}`);

    const taskSnapshot = await imageRef.putString(imageData, 'data_url') as any;
    const newUrl = await taskSnapshot.ref.getDownloadURL();

    return newUrl;
  }

  async deleteImageFromUrl(imageUrl: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const imageRef = companiesStorage.refFromURL(imageUrl);
        if (imageRef) {
          await imageRef.delete();
          resolve(true);
        }
        resolve(false);
      } catch (e: any) {
        console.log(e);
        reject(e);
      }
    });
  }

  async fileNameIsUnique(fileName: string, imageList: any) {
    return new Promise((resolve, reject) => {
      imageList.items.forEach((img: any) => {
        const storageFileNameWithoutExtension = img.name.replace(/\.[^/\\.]+$/, '');
        if (storageFileNameWithoutExtension === fileName) {
          resolve(false);
        }
      });
      resolve(true);
    });
  }

  async cleanImagesFromDir(location: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const storageRef = companiesStorage.ref();
        const dirRef = storageRef.child(`${location}`);
        const imagesToDelete = await dirRef.listAll();
        imagesToDelete.items.forEach(async (imageRef) => {
          await imageRef.delete();
        });
        resolve(true);
      } catch (e: any) {
        console.log(e);
        reject(e);
      }
    });
  }

  async downloadActiveJourney(company: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const activeJourneyRef = await db.collection('Companies').doc(company.id).collection('Journeys').doc(company.activeFormId)
          .get() as any;
        if (activeJourneyRef.data()) {
          const journeyData = activeJourneyRef.data().data;
          const fileName = `${company.name}-${company.activeFormId}`;
          const data = JSON.stringify(journeyData);
          const blob = new Blob([data], { type: 'application/json' });
          const href = await URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.download = `${fileName}.json`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          resolve(true);
        } else {
          reject();
        }
      } catch (e: any) {
        console.log(e);
        reject(e);
      }
    });
  }
}

const journeyManagementService = new JourneyManagementService();
export default journeyManagementService;
