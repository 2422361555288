import { useField, useFormikContext } from 'formik';
import React, {
  useEffect, useRef, useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { db } from '../../firebaseConfig';
import journeyManagementService from '../../services/JourneyManagementService';
import { useAppSelector } from '../../store/Hooks';
import './FormComponents.css';
import Folder from '../../assets/img/folder.png';
import { ReactComponent as Close } from '../../assets/img/close-icon.svg';
import Image from '../../model/Image';
import ImageType from '../../model/ImageType';
import Tooltip from '../Tooltip';

interface ContainerProps {
  label: string;
  name: string;
  imageType: string;
  service?: any;
  tooltip?:string;
}

const SingleImage: React.FC<ContainerProps> = ({
  label, name, imageType, service, tooltip,
}) => {
  const [field, , helpers] = useField(name);
  const inputRef = useRef<any>(null);

  const [imageUrl, setImageUrl] = useState<any>();
  const [company, setCompany] = useState<any>();
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadError, setFileUploadError] = useState('');

  const user = useAppSelector((state) => state.user);
  const journey = useAppSelector((state) => state.journey);
  const { submitForm } = useFormikContext();

  const getCompanyById = async (companyId: string) => {
    try {
      const companyRef = await db.collection('Companies').doc(companyId).get();
      if (companyRef.data()) {
        return companyRef.data();
      }
      throw new Error('Company is undefined');
    } catch (e: any) {
      console.log(e);
      return e;
    }
  };

  useEffect(() => {
    setImageUrl(field.value);
    getCompanyById(user.company)
      .then((response: any) => {
        setCompany(response);
      }).catch((e: any) => {
        console.error(e);
      });
  }, []);

  const uploadImage = async (file: File) => {
    try {
      if (file) {
        let imageLocation = '';
        switch (imageType) {
          case ImageType.LOGO:
            imageLocation = `${company.id}/${journey.id}/logo`;
            break;
          case ImageType.FAVICON:
            imageLocation = `${company.id}/${journey.id}/favicon`;
            break;
          case ImageType.HOMEPAGE:
            imageLocation = `${company.id}/${journey.id}/homepage`;
            break;
          case ImageType.SERVICE_IMAGES:
            imageLocation = `${company.id}/${journey.id}/services/${service.category}/${service.title}/images`;
            break;
          case ImageType.SERVICE_MAIN_IMAGE:
            if (service) {
              imageLocation = `${company.id}/${journey.id}/services/${service.category}/${service.title}/mainImage`;
            }
            break;
          case ImageType.ABOUT_US:
            imageLocation = `${company.id}/${journey.id}/about-us`;
            break;
          case ImageType.CHANGING_THE_GAME:
            imageLocation = `${company.id}/${journey.id}/changing-the-game`;
            break;
          default:
            console.log('image type not recognized');
            break;
        }

        const image: Image = {
          file,
          location: imageLocation,
          imageType,
        };
        setIsUploading(true);
        const newImageUrl = await journeyManagementService.uploadImage(image);
        setIsUploading(false);
        setFileUploadError('');
        setImageUrl(newImageUrl);
        helpers.setValue(newImageUrl);
        inputRef.current.value = '';
        submitForm();
      } else {
        throw new Error('File is undefined');
      }
    } catch (e: any) {
      inputRef.current.value = '';
      console.error(e);
      setFileUploadError(e.message);
      setIsUploading(false);
      helpers.setValue('');
      setImageUrl('');
      submitForm();
    }
  };

  const deleteImage = async () => {
    try {
      helpers.setValue('');
      setImageUrl('');
      if (imageUrl.includes('form-assets')) {
        submitForm();
        return;
      }
      await journeyManagementService.deleteImageFromUrl(imageUrl);
      submitForm();
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <div className="pa-field-wrapper full">
      <div className="pa-single-file-wrapper">
        <div>
          <label className="pa-field-label">
            {label}
            {tooltip
            && <Tooltip>{tooltip}</Tooltip>}
          </label>
          <div style={{ position: 'relative' }}>
            <label htmlFor={name} className="pa-field-label-file">
              <div className="pa-file-input-icon">
                <img src={Folder}></img>
              </div>
              <p className="pa-file-input-text">Click to select file <br /> or drag & drop</p>
            </label>
            <input ref={inputRef} id={name} className="pa-file-input" name={name} type="file" accept="image/*" onChange={(event: any) => {
              uploadImage(event.currentTarget.files[0]);
            }} />
            {fileUploadError && <div style={{ fontSize: 14, color: 'red', marginTop: 10 }}>{fileUploadError}</div>}
          </div>
        </div>
        <div className="pa-single-file-preview-wrapper">
          {
            (isUploading)
            && <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          }
          {
            (!isUploading && imageUrl)
            && <div className="pa-single-file-preview">
              <div className="pa-preview-delete-wrapper">
                <button type="submit" onClick={deleteImage} className="pa-btn warning icon-only">
                  <Close style={{
                    color: 'var(--light)', width: 15, height: 15,
                  }} />
                </button>
              </div>
              <img src={imageUrl} />
            </div>
          }
        </div>
      </div>
    </div >
  );
};

export default SingleImage;
