const ImageType = {
  ROOT: 'root',
  LOGO: 'logo',
  FAVICON: 'favicon',
  HOMEPAGE: 'homepage',
  SERVICE_IMAGES: 'serviceImages',
  SERVICE_MAIN_IMAGE: 'serviceMainImage',
  ABOUT_US: 'aboutUs',
  CHANGING_THE_GAME: 'changingTheGame',
};

export default ImageType;
