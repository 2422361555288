import { ReactComponent as ArrowRightSvg } from '../assets/img/arrow-right.svg';
import { ReactComponent as ArrowLeftSvg } from '../assets/img/arrow-left.svg';

interface FormButtonProps {
  onClick: () => void;
  title?: string;
  next?: boolean;
  back?: boolean;
  invert?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const FormButton: React.FC<FormButtonProps> = ({
  onClick,
  title,
  next = false,
  back = false,
  invert = false,
  style,
  children,
  disabled,
}) => {
  if (back) {
    return (
      <button
        className="pa-form-nav-button"
        onClick={onClick}
        style={{
          backgroundColor: 'var(--grey)',
          color: 'var(--primary)',
          ...style,
        }}
        disabled={!!disabled}
      >
        <ArrowLeftSvg style={{ marginRight: 10 }} />
        {title}
      </button>
    );
  } if (next) {
    return (
      <button
        className="pa-form-nav-button"
        onClick={onClick}
        style={{
          backgroundColor: (invert) ? 'var(--light)' : 'var(--primary)',
          color: (invert) ? 'var(--primary)' : 'var(--light)',
          ...style,
        }}
        disabled={!!disabled}
      >
        {title}
        {next && <ArrowRightSvg style={{ marginLeft: 10 }} />}
      </button>
    );
  }
  return (
    <button
      className="pa-form-nav-button"
      onClick={onClick}
      style={{
        backgroundColor: (invert) ? 'var(--light)' : 'var(--primary)',
        color: (invert) ? 'var(--primary)' : 'var(--light)',
        ...style,
      }}
      disabled={!!disabled}
    >
      {children}
    </button>
  );
};
export default FormButton;
