import { useFormikContext } from 'formik';
import { useContext, useEffect } from 'react';
import { FaClock } from 'react-icons/fa';
import FormTimeline from '../../components/formComponents/FormTimeline';
import FormButton from '../../components/FormNavButton';
import Spacer from '../../components/Spacer';
import JourneyStepsStatus from '../../model/JourneyStepsStatus';
import StepNavigationContext from '../../services/StepNavigationContext';
import { useAppSelector } from '../../store/Hooks';

interface ContainerProps {
  spec: any,
}
const SplitStepSplashscreen: React.FC<ContainerProps> = ({
  spec,
}) => {
  const { values, submitForm, setFieldValue }
  : {values:any, submitForm:any, setFieldValue:any} = useFormikContext();
  const journey = useAppSelector((state) => state.journey);
  const { nextStep, previousStep } = useContext(StepNavigationContext);

  useEffect(() => {
    if (values.steps[journey.currentStep]
      && values.steps[journey.currentStep].status
      && values.steps[journey.currentStep].status === JourneyStepsStatus.UNSEEN) {
      changeStepStatus(JourneyStepsStatus.VISITED);
    }
  }, []);

  const changeStepStatus = (status:string) => {
    setFieldValue(
      `steps[${journey.currentStep}].status`,
      status,
    );
  };

  const previousStepChild = async () => {
    previousStep();
    submitForm();
  };

  const nextStepChild = () => {
    nextStep();
    submitForm();
  };

  return (
    <div className="pa-splitscreen-container step pa-scroll-container">
      <div className="pa-splitscreen-col ta--c jc--sb ai--c fd--c bg--primary c--white has-journey-backdrop-graphics">
        <div></div>
        <div className="d--f fd--c ai--c">
          <div className="pa-splitscreen-step-number">{spec.major}</div>
          <h3 style={{ fontSize: '1.3em', fontWeight: 500, marginTop: 10 }}>STEP {spec.major}</h3>
          <Spacer height={30} />
          <h1 style={{ fontSize: '1.6em' }}>{spec.title}</h1>
          <p>{spec.content.leftCol.description}</p>
          <Spacer height={50} />
          <div className='pa-form-step-nav-container center'>
            <FormButton
              onClick={() => { previousStepChild(); }}
              back
              style={{
                marginRight: 10,
              }}
            />
            <FormButton
              onClick={() => { nextStepChild(); }}
              title={spec.content.leftCol.btnText}
              invert
              next
            />
          </div>
          <Spacer height={20} />
          <div style={{ opacity: 0.6, fontSize: 14, fontWeight: 200 }}>
            <FaClock style={{ marginRight: 10, paddingTop: 2 }} />{spec.content.leftCol.lengthText}
          </div>
        </div>
        <div style={{ margin: '15px 0' }}>
          <FormTimeline />
        </div>
      </div>
      <div className="pa-splitscreen-col jc--c fd--c">
        <h2 className="pa-splitscreen-explanation-title">{spec.content.rightCol.title}</h2>
        <div className="pa-splitscreen-explanation" dangerouslySetInnerHTML={{ __html: spec.content.rightCol.description }} />
        {spec.content.rightCol.imageUrl
          && <img className="pa-splitscreen-img" src={spec.content.rightCol.imageUrl} />}
        <a target="_blank" href={spec.content.rightCol.footnoteUrl} className="pa-splitscreen-footnote fwe--light ft-italic">
          <p className="pa-footnote">{spec.content.rightCol.footnoteText}</p>
        </a>
      </div>
    </div >
  );
};
export default SplitStepSplashscreen;
