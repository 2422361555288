interface SpacerProps {
  height?: number,
}

const Spacer: React.FC<SpacerProps> = ({
  height = 20,
}) => (
  <div style={{ width: '100%', height }} />
);
export default Spacer;
