import { useFormikContext } from 'formik';
import { Fragment } from 'react';
import { FaCaretUp, FaCheck } from 'react-icons/fa';
import JourneyStepsStatus from '../../model/JourneyStepsStatus';
import journeyManagementService from '../../services/JourneyManagementService';
import { useAppSelector } from '../../store/Hooks';

const FormTimeline: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const journey = useAppSelector((state) => state.journey);
  const { values } : { values : any } = useFormikContext();

  const renderTimeline = () => {
    let major = 0;
    return (
      (journey.currentStep)
        && <div className="pa-timeline-container">
          <div className='pa-timeline-item success' onClick={() => journeyManagementService.goToSpecificStep(user.company, 0)}>
            <div className='pa-timeline-item-head'>
              <div className="pa-timeline-item-number">
                <FaCheck />
              </div>
              <div className="pa-timeline-item-line"></div>
            </div>
            <div className="pa-timeline-item-title">Let's get started!</div>
          </div>
          {journey.specs.steps.map((step: any, key: number) => {
            if (step.major !== major && step.major) {
              major = step.major;

              // look ahead for status
              let lookAheadIndex = key;
              let statusColor = '';
              let someVisited = false;
              let allApproved = true;
              while (
                lookAheadIndex < values.steps.length
                && values.steps[lookAheadIndex]
                && values.steps[lookAheadIndex].major === major
              ) {
                lookAheadIndex += 1;
                if (values.steps[lookAheadIndex - 1].status === JourneyStepsStatus.ERROR) {
                  allApproved = false;
                  statusColor = 'danger';
                  break;
                }
                if (values.steps[lookAheadIndex - 1].status === JourneyStepsStatus.VISITED) {
                  allApproved = false;
                  someVisited = true;
                  // eslint-disable-next-line no-continue
                  continue;
                }
                if (values.steps[lookAheadIndex - 1].status === JourneyStepsStatus.APPROVED) {
                  // eslint-disable-next-line no-continue
                  continue;
                }
                allApproved = false;
              }
              if (someVisited && statusColor !== 'danger') {
                statusColor = 'warning';
              }
              if (allApproved && statusColor !== 'danger') {
                statusColor = 'success';
              }
              return (
                <Fragment key={key}>
                  <div className={`pa-timeline-item ${statusColor} ${(journey.specs.steps[journey.currentStep].major === major) ? 'current' : ''}`}
                    onClick={() => journeyManagementService.goToSpecificStep(user.company, key)}
                  >
                    <div className="pa-timeline-item-title">
                      {step.title}
                    </div>
                    <div className='pa-timeline-item-head'>
                      <div className="pa-timeline-item-number">{major}</div>
                      <div className="pa-timeline-item-line"></div>
                    </div>
                    <div className='pa-timeline-item-here'>
                      <FaCaretUp className='caret' />
                      <span>You are here</span>
                    </div>
                  </div>
                </Fragment>
              );
            }
            return (null);
          })}
          <div className='pa-timeline-item' style={{ minWidth: 0 }}>
            <div className="pa-timeline-item-number">
              <FaCheck />
            </div>
          </div>
        </div>
    );
  };

  return (
    <div className="pa-form-timeline-wrapper">
      {(values && values.steps && journey && journey.specs)
      && renderTimeline()}
    </div>
  );
};
export default FormTimeline;
