import { FieldHookConfig, useField } from 'formik';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { FaPaintBrush } from 'react-icons/fa';
import Tooltip from '../Tooltip';

import './FormComponents.css';

interface ContainerProps {
  label?: string;
  tooltip?:string;
}

const ColorPicker: React.FC<FieldHookConfig<string> & ContainerProps> = ({
  label,
  tooltip,
  ...props
}) => {
  const [field] = useField(props);
  const [, , helpers] = useField(field.name);
  const [colorCode, setColorCode] = useState<any>(field.value);

  const updateColorCode = (color: any) => {
    setColorCode(color.hex);
    helpers.setValue(color.hex);
  };

  return (
    <div className="pa-colorpicker-field-wrapper">
      <label className='pa-field-label'>
        {label}
        {tooltip
          && <Tooltip>{tooltip}</Tooltip>}
      </label>
      <div className="pa-colorpicker-btn">
        <div className="pa-colorpicker-btn-icon">
          <FaPaintBrush color='#0069B1' />
        </div>
        <p className="pa-colorpicker-btn-text">Choose color</p>
        <div className="pa-colorpicker-btn-preview" style={{ backgroundColor: `${colorCode}` }} ></div>
      </div>
      <SketchPicker
        color={colorCode}
        onChangeComplete={(e) => updateColorCode(e)}
      />
    </div>
  );
};

export default ColorPicker;
