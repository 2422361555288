/* eslint-disable class-methods-use-this */
import { parseAsync } from 'json2csv';

import firebase from 'firebase';
import { db } from '../firebaseConfig';
import { mapSectorsToString, mapThemesToString } from './utilities';
import notificationManagementService from './NotificationManagementService';

class CompanyManagementService {
  async createCompany(id:string, name: string, sector: number, themeId: string) {
    await db.collection('Companies').doc(id).set({
      id,
      name,
      sector,
      themeId,
      siteUrl: '',
      siteAdminUrl: '',
      WPUser: '',
      WPPW: '',
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  async getCompany(id:string) {
    const companyDoc = await db.collection('Companies').doc(id).get();
    const companyData = companyDoc.data();
    return companyData;
  }

  async getAllServices(formId: any, companyId: string) {
    try {
      const services: { value: any; label: any; }[] = [];
      const formRef = await db.collection('Companies').doc(companyId).collection('Journeys').doc(formId)
        .get();
      if (formRef) {
        const formData = formRef.data();
        const servicesStep = formData?.data.steps.find(
          (s: any) => s.type === 'services',
        );
        servicesStep.services.forEach((service: any) => {
          services.push({
            value: (service.id) ? service.id : service.title,
            label: service.title,
          });
        });
      }
      return services;
    } catch (e: any) {
      console.log(e);
      return e;
    }
  }

  async addLog(id:string, user:string, message:string) {
    db.collection('Companies').doc(id).collection('Logs').add({
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      message,
      user,
    });
  }

  async changeStatus(id:string, status:string, sendNotification = true) {
    db.collection('Companies').doc(id).update({
      formStatus: status,
    });
    if (status === 'Website is live') {
      db.collection('Companies').doc(id).update({
        goLiveDate: firebase.firestore.Timestamp.fromDate(new Date()),
      });
    }

    if (sendNotification) {
      notificationManagementService.sendStatusChangeNotification(id, status);
    }
  }

  async exportCSV() {
    const companiesSnapshot = await firebase
      .firestore()
      .collection('Companies')
      .get();

    const companies = companiesSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        companyName: data.name,
        creationDate: new Date(data.timestamp.seconds * 1000).toString(),
        theme: mapThemesToString(data.themeId),
        sector: mapSectorsToString(data.sector),
        status: data.formStatus,
        goLiveDate: data.goLiveDate ? new Date(data.goLiveDate * 1000).toString() : 'not live',
        siteUrl: data.siteUrl,
        WPUsername: data.WPUser,
        WPPassword: data.WPPW,
        companyGUID: data.id,
      };
    });

    const fields = [
      'companyName',
      'creationDate',
      'theme',
      'sector',
      'status',
      'goLiveDate',
      'siteUrl',
      'WPUsername',
      'WPPassword',
      'companyGUID',
    ];

    const output = await parseAsync(companies, { fields });

    const blob = new Blob([output], { type: 'text/csv' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = 'all-company-data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const companyManagmentService = new CompanyManagementService();
export default companyManagmentService;
