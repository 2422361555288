import { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';

type AccordionProps = {
  title: any,
}

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div>
      <div className='d--f ai--c'>
        {title}
        <FaAngleDown
          onClick={() => setIsActive(!isActive)}
          style={{
            cursor: 'pointer',
            fontSize: 26,
            color: 'var(--secondary)',
          }} />
      </div>
      {isActive && <div style={{
        marginTop: 30,
      }}>{children}</div>}
    </div>
  );
};
export default Accordion;
