import { FieldArray, useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import FormTimeline from '../../../components/formComponents/FormTimeline';
import JourneyStepsStatus from '../../../model/JourneyStepsStatus';
import journeyManagementService from '../../../services/JourneyManagementService';
import StepNavigationContext from '../../../services/StepNavigationContext';
import { useAppSelector } from '../../../store/Hooks';
import '../Steps.css';
import ActionBtnStep from './ActionBtnStep';
import ChooseServiceStep from './ChooseServiceStep';
import InformationStep from './InformationStep';
import MainImage from './MainImage';
import ServiceDescriptionStep from './ServiceDescriptionStep';
import ServicesListStep from './ServicesListStep';
import SummaryStep from './SummaryStep';

interface ContainerProps {
  spec: any,
}

const ServicesStep: React.FC<ContainerProps> = ({
  spec,
}) => {
  let arrayHelpers: any;
  const user = useAppSelector((state) => state.user);
  const journey = useAppSelector((state) => state.journey);
  const [step, setStep] = useState(0);
  const [busy, setBusy] = useState(true);
  const [categories, setCategories] = useState<any>();
  const [services, setServices] = useState<any>();

  const [activeServiceIndex, setActiveServiceIndex] = useState<any>();

  const { values, setFieldValue }: { values: any, setFieldValue: any } = useFormikContext();
  const { nextStep, previousStep } = useContext(StepNavigationContext);

  useEffect(() => {
    if (values.steps[journey.currentStep]
      && values.steps[journey.currentStep].status
      && values.steps[journey.currentStep].status === JourneyStepsStatus.UNSEEN) {
      changeStepStatus(JourneyStepsStatus.VISITED);
    }
  }, []);

  const changeStepStatus = (status:string) => {
    setFieldValue(
      `steps[${journey.currentStep}].status`,
      status,
    );
  };

  useEffect(() => {
    const serviceCategoryStep = values.steps.find((s: any) => s.id === 'serviceCategories');
    if (serviceCategoryStep) {
      const categoriesTemp = serviceCategoryStep.fields.find(
        (s: any) => s.serviceCategories,
      );
      setCategories(categoriesTemp.serviceCategories);
      setServices(values.steps.find((s: any) => s.id === 'services').services);
      setBusy(false);
    } else {
      console.log('Error when fetching service categories');
    }
  }, [values]);

  const updateStep = async (newStep: number) => {
    if (newStep < 0) {
      journeyManagementService.previousStep(user.company);
    }
    setStep(newStep);
  };

  const updateActiveServiceIndex = async (index: number) => {
    setActiveServiceIndex(index);
  };

  const bindArrayHelpers = (helpers: any) => {
    arrayHelpers = helpers;
  };

  const renderServicesSteps = () => {
    switch (step) {
      case 0:
        return <ServicesListStep
          step={step}
          spec={spec}
          updateStep={updateStep}
          updateActiveServiceIndex={updateActiveServiceIndex}
          categories={categories}
          services={services}
          arrayHelpers={arrayHelpers}
          nextStep={nextStep}
          previousStep={previousStep}
        >
        </ServicesListStep>;
      case 1:
        return <InformationStep
          step={step}
          spec={spec}
          updateStep={updateStep}
          services={services}
          activeServiceIndex={activeServiceIndex}
          arrayHelpers={arrayHelpers}
        >
        </InformationStep>;
      case 2:
        return <ChooseServiceStep
          journeyStep={journey.currentStep}
          step={step}
          spec={spec}
          updateStep={updateStep}
          activeServiceIndex={activeServiceIndex}
          updateActiveServiceIndex={updateActiveServiceIndex}
          categories={categories}
          services={services}
          arrayHelpers={arrayHelpers}
        >
        </ChooseServiceStep>;
      case 3:
        return <ServiceDescriptionStep
          journeyStep={journey.currentStep}
          step={step}
          spec={spec}
          updateStep={updateStep}
          activeServiceIndex={activeServiceIndex}
          services={services}
          arrayHelpers={arrayHelpers}
        >
        </ServiceDescriptionStep>;
      case 4:
        return <MainImage
          journeyStep={journey.currentStep}
          step={step}
          spec={spec}
          updateStep={updateStep}
          activeServiceIndex={activeServiceIndex}
          services={services}
          arrayHelpers={arrayHelpers}
        >
        </MainImage>;
      case 5:
        return <ActionBtnStep
          journeyStep={journey.currentStep}
          step={step}
          spec={spec}
          updateStep={updateStep}
          activeServiceIndex={activeServiceIndex}
          services={services}
          arrayHelpers={arrayHelpers}
        >
        </ActionBtnStep>;
      case 6:
        return <SummaryStep
          journeyStep={journey.currentStep}
          step={step}
          spec={spec}
          updateStep={updateStep}
          activeServiceIndex={activeServiceIndex}
          services={services}
        >
        </SummaryStep>;
      default:
        return <ServicesListStep
          step={step}
          spec={spec}
          updateStep={updateStep}
          updateActiveServiceIndex={updateActiveServiceIndex}
          categories={categories}
          services={services}
          arrayHelpers={arrayHelpers}
          nextStep={nextStep}
          previousStep={previousStep}
        />;
    }
  };

  return (
    busy
      ? <div>Loading</div>
      : <div className={`pa-service-step ${(step !== 0) && 'open'}`}>
        <div className="standard-form-container has-journey-backdrop-graphics light">
          <div className="pa-form-content-area">
            <div className="pa-container">
              <FieldArray
                name={`steps[${journey.currentStep}].services`}
                render={(arrHelpers: any) => {
                  bindArrayHelpers(arrHelpers);
                  return (renderServicesSteps());
                }}
              />
            </div>
          </div>
          <div>
            <FormTimeline />
          </div>
        </div>
      </div>
  );
};
export default ServicesStep;
