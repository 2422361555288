import { Link } from 'react-router-dom';

const SuperAdminNav: React.FC = () => (
  <>
    <Link to='/admin' style={{ padding: 10 }}>Admin home</Link>
    <Link to='/admin/default-forms' style={{ padding: 10 }}>Default forms</Link>
    <Link to='/admin/admin-tools' style={{ padding: 10 }}>Admin tools</Link>
  </>
);
export default SuperAdminNav;
