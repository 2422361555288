import { FieldHookConfig, useField } from 'formik';
import Text from './Text';
import Select from './Select';

interface ContainerProps {
  label?: string;
  fieldValidation?:string;
}

const SocialMedia: React.FC<FieldHookConfig<string | any[]> & ContainerProps> = ({
  label,
  fieldValidation,
  ...props
}) => {
  const [field] = useField(props);

  return (
    <div className="d--f fd--r pa-social-media-repeater-row" style={{ width: '100%', maxWidth: '600px' }}>
      {
        label
        && <p> {label}</p>
      }
      <Select label='Platform' name={`${field.name}.type`} options='socialMedias'></Select>
      <Text
        name={`${field.name}.link`}
        type="text"
        label='Link'
        style={{ width: '60%', marginRight: 20 }}
        fieldValidation={fieldValidation}
        full
      />
    </div >
  );
};

export default SocialMedia;
