import { useEffect, useState } from 'react';
import { FaQuestionCircle, FaTimes } from 'react-icons/fa';
import './Components.css';

const Tooltip: React.FC = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      setTimeout(() => { setIsActive(false); }, 10000);
    }
  }, [isActive]);

  return (
    <div className='pa-tooltip'>
      <div className='pa-tooltip-icon' onClick={() => setIsActive(!isActive)}>
        <FaQuestionCircle color="var(--secondary)" />
      </div>
      <div
        className={`pa-tooltip-description ${isActive && 'active'}`}>
        <div className="pa-tooltip-close" onClick={() => setIsActive(false)}><FaTimes /></div>
        {children}
      </div>
    </div>
  );
};
export default Tooltip;
