import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Journey from '../../model/Journey';

// Define the initial state using that type
const initialState: Journey = {
  id: '',
  specs: {},
  data: {},
  currentStep: -1,
} as Journey;

export const journeySlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateJourney: (state, { payload }: PayloadAction<Journey>) => ({
      ...state,
      ...payload,
    }),
    resetJourneyState: () => (
      initialState
    ),
  },
});

export const {
  updateJourney,
  resetJourneyState,
} = journeySlice.actions;

export default journeySlice;
