import {
  FieldHookConfig, getIn, useField, useFormikContext,
} from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Tooltip from '../Tooltip';
import './FormComponents.css';

interface TextProps {
  label?: string;
  full?:boolean;
  tooltip?:string;
  invert?:boolean;
  fieldValidation?: string;
}

const Text: React.FC<FieldHookConfig<string> & TextProps> = ({
  label,
  full,
  tooltip,
  invert,
  fieldValidation,
  ...props
}) => {
  const { errors, validateField } : {errors:any, validateField:any} = useFormikContext();
  const [thisError, setThisError] = useState('');

  useEffect(() => {
    if (getIn(errors, field.name)) {
      setThisError(getIn(errors, field.name));
    } else {
      setThisError('');
    }
  }, [errors]);

  const validateTextField = async (value:string) => {
    let schema = Yup.string().max(100, 'Too long');
    if (fieldValidation?.includes('email')) {
      if (fieldValidation?.includes('required')) {
        schema = Yup.string().required('This field is required').email('Wrong email format');
      } else {
        schema = Yup.string().email('Wrong email format');
      }
    } else if (fieldValidation?.includes('domain')) {
      const regMatch = /^(?!.*(http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
      if (fieldValidation?.includes('required')) {
        schema = Yup.string().required('This field is required').matches(regMatch, 'Wrong url format, please use www.mydomain.com or mydomain.com (no http or https)');
      } else {
        schema = Yup.string().matches(regMatch, 'Wrong url format, please use www.mydomain.com or mydomain.com (no http or https)');
      }
    } else if (fieldValidation?.includes('url')) {
      if (fieldValidation?.includes('required')) {
        schema = Yup.string().required('This field is required').url('Wrong url format, please use https://example.com');
      } else {
        schema = Yup.string().url('Wrong url format, please use https://example.com');
      }
    } else if (fieldValidation?.includes('required')) {
      schema = Yup.string().required('This field is required');
    }
    let error;
    try {
      await schema.validate(value);
    } catch (err:any) {
      // eslint-disable-next-line prefer-destructuring
      error = err.errors[0];
    }
    return error;
  };
  const allProps = {
    ...props,
    validate: validateTextField,
  };

  const [field] = useField(allProps);

  useEffect(() => {
    validateField(field.name);
  }, []);

  return (
    <div className="pa-field-wrapper" style={props.style}>
      {
        label
        && <label className="pa-field-label">
          {label}
          {tooltip
          && <Tooltip>{tooltip}</Tooltip>}
        </label>
      }
      <input className={`pa-input ${invert && 'invert'}`} style={{ width: (full) ? '100%' : '80%' }} {...field} maxLength={100} />
      <div className="pa-input-error">{thisError}</div>
    </div>
  );
};

export default Text;
