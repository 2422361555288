import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import './FormComponents.css';
import Select from './Select';
import Text from './Text';
import Textarea from './Textarea';

interface ContainerProps {
  label?: string;
  spec: any;
  options: any
}

const SelectTextTextarea: React.FC<FieldHookConfig<string> & ContainerProps> = ({
  label, spec, options, ...props
}) => {
  const [field] = useField(props);
  return (
    <div className="pa-selecttexttextarea-wrap">
      {
        label
        && <label className="pa-field-label">{label}</label>
      }
      <Select
        name={`${field.name}.icon`}
        label={spec?.selectLabel}
        options={spec?.optionsType}
      />
      <Text
        name={`${field.name}.text`}
        label={spec?.textLabel}
        full
        style={{
          marginRight: 20,
        }}
      />
      <Textarea
        name={`${field.name}.textArea`}
        label={spec?.textAreaLabel}
      />
    </div>
  );
};

export default SelectTextTextarea;
