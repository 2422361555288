import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { db } from '../firebaseConfig';
import companyManagmentService from '../services/CompanyManagementService';
import { useAppSelector } from '../store/Hooks';

const Logs: React.FC<{companyId:string}> = ({ companyId }) => {
  const [logs, setLogs] = useState([]) as any[];
  const [logMessage, setLogMessage] = useState('');
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let unsubscribe = () => {};

    async function fetchLogs() {
      unsubscribe = db.collection('Companies').doc(companyId).collection('Logs').orderBy('timestamp', 'desc')
        .onSnapshot((docs) => {
          const logsArr: firebase.firestore.DocumentData[] = [];
          docs.forEach((doc) => {
            logsArr.push(doc.data());
          });
          setLogs(logsArr);
        });
    }
    fetchLogs();

    return unsubscribe;
  }, []);

  const addLog = (e:any, id:string) => {
    e.preventDefault();
    if (logMessage) {
      companyManagmentService.addLog(id, user.name, logMessage);
    }
    setLogMessage('');
  };

  return (
    <div className="pa-company-table-logs">
      <div className="pa-company-table-logs-head">
        <span style={{ width: '20%' }}>Date</span>
        <span style={{ width: '20%' }}>User</span>
        <span style={{ width: '60%' }}>Log</span>
      </div>
      <div className="pa-company-table-logs-content">
        <div className="pa-logs-wrap pa-scroll-container">
          {logs && logs.map((log: any, key: any) => (
            <div className="pa-company-table-logs-item" key={key}>
              <div className="date">{new Date(log.timestamp.seconds * 1000).toLocaleString()}</div>
              <div className="user">{log.user}</div>
              <div className="log">{log.message}</div>
            </div>
          ))}
        </div>
        <form onSubmit={(e) => addLog(e, companyId)} className="pa-company-table-log-add">
          <input
            type="text"
            placeholder="add log"
            value={logMessage}
            onChange={(e) => setLogMessage(e.target.value)}
            className="pa-logs-input"
          />
          <input type="submit" value="Submit" className='pa-logs-btn' />
        </form>
      </div>
    </div>
  );
};
export default Logs;
