import { Field } from 'formik';
import '../Steps.css';
import { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import RichText from '../../../components/formComponents/RichText';
import FormButton from '../../../components/FormNavButton';
import Preview from '../../../components/formComponents/Preview';
import Spacer from '../../../components/Spacer';

interface ContainerProps {
  journeyStep: any,
  step: number,
  spec: any,
  updateStep: any,
  services: any,
  activeServiceIndex: any,
  arrayHelpers: any,
}

const ServiceDescriptionStep: React.FC<ContainerProps> = ({
  step, updateStep, activeServiceIndex, journeyStep, services, spec, arrayHelpers,
}) => {
  const close = () => {
    if (!services[activeServiceIndex].title) {
      arrayHelpers.remove(activeServiceIndex);
    }
    updateStep(0);
  };

  const previousStep = async () => {
    updateStep(step - 1);
  };

  const nextStep = async () => {
    updateStep(step + 1);
  };

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      e.returnValue = true;
    };

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, []);

  return (
    <>
      <div className="d--f ai--c">
        <h1 className="standard-form-title c--primary">
          Add or modify a service
        </h1>
        <Preview spec={spec} />
      </div>
      <div className="standard-form-wrapper pa-scroll-container bg--primary c--light">
        <div style={{
          position: 'absolute', top: 0, right: 0, padding: 10, width: 44, cursor: 'pointer',
        }} onClick={close}>
          <FaTimes style={{ opacity: 0.5, fontSize: 20, color: 'var(--light)' }} />
        </div>
        <h4 className="ta--c">{services[activeServiceIndex]?.title}</h4>
        <Spacer height={20} />
        <h3>Describe this service in a few paragraphs</h3>
        <p>
            Describe what is included in this service. This will appear in part on the “homepage”
            and on the “services” page and in the “learn more” page dedicated solely to this
            service.
        </p>
        <Field component={RichText} name={`steps[${journeyStep}].services[${activeServiceIndex}].description`} />
      </div>
      <div className='pa-form-step-nav-container' style={{ justifyContent: 'center' }}>
        <FormButton
          onClick={() => { previousStep(); }}
          back
          style={{
            marginRight: 10,
            background: 'var(--grey)',
            color: 'var(--light)',
          }}
        />
        <FormButton
          onClick={() => { nextStep(); }}
          title='Continue'
          next
        />
      </div>
    </>
  );
};
export default ServiceDescriptionStep;
