import { useFormikContext } from 'formik';
import { Fragment, useContext, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import FormButton from '../../components/FormNavButton';
import { JourneyStatus } from '../../model/journeyStatus';
import JourneyStepsStatus from '../../model/JourneyStepsStatus';
import companyManagmentService from '../../services/CompanyManagementService';
import journeyManagementService from '../../services/JourneyManagementService';
import StepNavigationContext from '../../services/StepNavigationContext';
import { useAppSelector } from '../../store/Hooks';

interface ContainerProps {
  spec: any,
}

const TableOfContents: React.FC<ContainerProps> = ({
  spec,
}) => {
  const user = useAppSelector((state) => state.user);
  const journey = useAppSelector((state) => state.journey);
  const { values, submitForm, setFieldValue }
  : {values:any, submitForm:any, setFieldValue:any} = useFormikContext();
  const { nextStep, previousStep } = useContext(StepNavigationContext);

  const previousStepChild = async () => {
    previousStep();
    submitForm();
  };

  const nextStepChild = async () => {
    companyManagmentService.changeStatus(user.company, JourneyStatus.FILLING_EFORM);
    nextStep();
    submitForm();
  };

  useEffect(() => {
    if (values.steps[journey.currentStep]
      && values.steps[journey.currentStep].status
      && values.steps[journey.currentStep].status === JourneyStepsStatus.UNSEEN) {
      changeStepStatus(JourneyStepsStatus.VISITED);
    }
  }, []);

  const changeStepStatus = (status:string) => {
    setFieldValue(
      `steps[${journey.currentStep}].status`,
      status,
    );
  };

  const renderTOC = (steps: any) => {
    let major = 0;
    let counter = 0;
    return (
      <div className="pa-toc-container pa-scroll-container dark">
        <div className='pa-toc-item success' style={{ cursor: 'pointer' }} onClick={() => journeyManagementService.goToSpecificStep(user.company, 0)}>
          <div className="pa-toc-item-header">
            <div className="pa-toc-item-number">
              <FaCheck />
            </div>
            <div className="pa-toc-item-line"></div>
          </div>
          <div className="pa-toc-item-desc">
            <h3>Let's get started!</h3>
          </div>
        </div>
        {steps.map((step: any, key: number) => {
          if (step.major !== major && step.major) {
            counter += 1;
            major = step.major;

            // look ahead for status
            let lookAheadIndex = key;
            let statusColor = '';
            let someVisited = false;
            let allApproved = true;
            while (
              lookAheadIndex < values.steps.length
              && values.steps[lookAheadIndex]
              && values.steps[lookAheadIndex].major === major
            ) {
              lookAheadIndex += 1;
              if (values.steps[lookAheadIndex - 1].status === JourneyStepsStatus.ERROR) {
                allApproved = false;
                statusColor = 'danger';
                break;
              }
              if (values.steps[lookAheadIndex - 1].status === JourneyStepsStatus.VISITED) {
                allApproved = false;
                someVisited = true;
                // eslint-disable-next-line no-continue
                continue;
              }
              if (values.steps[lookAheadIndex - 1].status === JourneyStepsStatus.APPROVED) {
                // eslint-disable-next-line no-continue
                continue;
              }
              allApproved = false;
            }
            if (someVisited && statusColor !== 'danger') {
              statusColor = 'warning';
            }
            if (allApproved && statusColor !== 'danger') {
              statusColor = 'success';
            }

            return (
              <Fragment key={key}>
                <div key={key} className={`pa-toc-item ${statusColor}`} style={{ cursor: 'pointer' }} onClick={() => journeyManagementService.goToSpecificStep(user.company, key)}>
                  <div className="pa-toc-item-header">
                    <div className="pa-toc-item-number">{major}</div>
                    <div className="pa-toc-item-line">
                      <div className="pa-toc-item-line-curve">
                        <div className="pa-toc-item-line-curve-segment"></div>
                        <div className="pa-toc-item-line-curve-inner"></div>
                        <div className="pa-toc-line-curve-dot"></div>
                        <div className="pa-toc-line-curve-dot end"></div>
                      </div>
                    </div>
                  </div>
                  <div className="pa-toc-item-desc">
                    <div className='pa-toc-item-step-title'>{`step ${major}`}</div>
                    <h3>{step.title}</h3>
                  </div>
                </div>
                {
                  (counter % 4 === 0) && <div className="pa-toc-newline" key={`${key}-${counter}`}>
                    <div className="pa-toc-newline-line">
                      <div className="pa-toc-item-line-curve">
                        <div className="pa-toc-item-line-curve-segment"></div>
                        <div className="pa-toc-item-line-curve-inner"></div>
                        <div className="pa-toc-line-curve-dot"></div>
                        <div className="pa-toc-line-curve-dot end"></div>
                      </div>
                    </div>
                  </div>
                }
              </Fragment>
            );
          }
          return (null);
        })}
        <div className='pa-toc-item'>
          <div className="pa-toc-item-header">
            <div className="pa-toc-item-number">
              <FaCheck />
            </div>
          </div>
          <div className="pa-toc-item-desc">
            <h3>Your website is ready to go live!</h3>
          </div>
        </div>
      </div >
    );
  };

  return (
    <div className="pa-fullscreen-container has-journey-backdrop-graphics pa-scroll-container">
      <div className="pa-container">
        <h1 style={{ fontSize: '1.6em' }}>{spec.title}</h1>
        <p>{spec.content.description}</p>
        {(journey.specs && journey.specs.steps)
         && renderTOC(journey.specs.steps)}
        <div className='pa-form-step-nav-container'>
          <FormButton
            onClick={() => { previousStepChild(); }}
            back
            style={{
              marginRight: 10,
            }}
          />
          <FormButton
            onClick={() => { nextStepChild(); }}
            title={spec.content.btnText}
            invert
            next
          />
        </div>
      </div>
    </div>
  );
};
export default TableOfContents;
