import {
  FaDownload,
} from 'react-icons/fa';
import './Pages.css';
import { useAppSelector } from '../store/Hooks';
import companyManagmentService from '../services/CompanyManagementService';
import SuperAdminNav from '../components/SuperAdminNav';
import CompanyTable from '../components/CompanyTable';
import Spacer from '../components/Spacer';

const Companies: React.FC = () => {
  const user = useAppSelector((state) => state.user);

  return (
    <div>
      {user.superAdmin && <SuperAdminNav />}
      <Spacer height={40} />
      <div className="p--20">
        <div className="d--f ai--c">
          <h1>Companies</h1>
          <FaDownload
            onClick={() => companyManagmentService.exportCSV()}
            style={{
              fontSize: 20,
              color: 'var(--primary)',
              marginLeft: 20,
              cursor: 'pointer',
            }} />
        </div>
        <Spacer />
        <CompanyTable />
      </div>
    </div>
  );
};
export default Companies;
