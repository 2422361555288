import { FieldArray, useField, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { db } from '../../firebaseConfig';
import ImageType from '../../model/ImageType';
import Image from '../../model/Image';
import journeyManagementService from '../../services/JourneyManagementService';
import { useAppSelector } from '../../store/Hooks';
import './FormComponents.css';
import Folder from '../../assets/img/folder.png';
import { ReactComponent as Close } from '../../assets/img/close-icon.svg';

interface ContainerProps {
  label: string;
  name: string;
  imageType: string;
  service: any
}

const MultipleImages: React.FC<ContainerProps> = ({
  label, name, imageType, service,
}) => {
  const [field] = useField(name);
  const inputRef = useRef<any>(null);

  const { submitForm } = useFormikContext();
  const user = useAppSelector((state) => state.user);
  const journey = useAppSelector((state) => state.journey);
  const [isUploading, setIsUploading] = useState(false);
  const [company, setCompany] = useState<any>();
  const [fileUploadError, setFileUploadError] = useState('');

  const getCompanyById = async (companyId: string) => {
    try {
      const companyRef = await db.collection('Companies').doc(companyId).get();
      if (companyRef.data()) {
        return companyRef.data();
      }
      throw new Error('Company is undefined');
    } catch (e: any) {
      console.log(e);
      return e;
    }
  };

  useEffect(() => {
    getCompanyById(user.company)
      .then((response: any) => {
        setCompany(response);
      }).catch((e: any) => {
        console.error(e);
      });
  }, []);

  const uploadImages = async (files: FileList, arrayHelpers: any) => {
    setIsUploading(true);

    try {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file) {
          // eslint-disable-next-line no-await-in-loop
          const imageUrl = await uploadImage(file);
          arrayHelpers.push(imageUrl);
          let imagesUrlTemp = [];
          if (field.value) {
            imagesUrlTemp = [...field.value];
          }
          imagesUrlTemp.push(imageUrl);
        }
      }
      setIsUploading(false);
      setFileUploadError('');
      inputRef.current.value = '';
      submitForm();
    } catch (e: any) {
      inputRef.current.value = '';
      console.error(e);
      setFileUploadError(e.message);
      setIsUploading(false);
    }
  };

  const uploadImage = async (file: File) => {
    if (file) {
      let imageLocation = '';
      switch (imageType) {
        case ImageType.SERVICE_IMAGES:
          imageLocation = `${company.id}/${journey.id}/services/${service.category}/${service.title}/images`;
          break;
        default:
          console.log('image type not recognized');
          break;
      }

      const image: Image = {
        file,
        location: imageLocation,
        imageType,
      };

      const newImageUrl = await journeyManagementService.uploadImage(image);
      return newImageUrl;
    }
    throw new Error('File is undefined');
  };

  const deleteImage = async (arrayHelpers: any, index: number, imageUrl: string) => {
    try {
      if (imageUrl.includes('form-assets')) {
        arrayHelpers.remove(index);
        submitForm();
        return;
      }
      await journeyManagementService.deleteImageFromUrl(imageUrl);
      arrayHelpers.remove(index);
      submitForm();
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <div className="pa-field-wrapper full">
      <FieldArray name={field.name}
        render={(arrayHelpers) => (
          <div className="pa-multiple-file-container">
            <div className='pa-multiple-file-input'>
              <label className="pa-field-label">
                {label}
              </label>
              <div style={{ position: 'relative' }}>
                <label htmlFor={name} className="pa-field-label-file">
                  <div className="pa-file-input-icon">
                    <img src={Folder}></img>
                  </div>
                  <p className="pa-file-input-text">Click to select files <br /> or drag & drop</p>
                </label>
                <input className="pa-file-input" ref={inputRef} multiple name={name} id={name} type="file" accept="image/*" onChange={(event: any) => {
                  uploadImages(event.currentTarget.files, arrayHelpers);
                }} />
                {fileUploadError && <div style={{ fontSize: 14, color: 'red', marginTop: 10 }}>{fileUploadError}</div>}
              </div>
            </div>
            <div className='pa-multiple-file-preview-wrapper'>
              {
                (isUploading)
            && <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
              }
              {
                field.value && field.value.map((image: any, index: any) => (
                  <div key={index} className="pa-multiple-file-preview">
                    <div className="pa-preview-delete-wrapper">
                      <button type="submit" onClick={() => deleteImage(arrayHelpers, index, image)} className="pa-btn warning icon-only">
                        <Close style={{
                          color: 'var(--light)', width: 15, height: 15,
                        }} />
                      </button>
                    </div>
                    <img src={image} />
                  </div>
                ))
              }
            </div>
          </div>
        )} />
    </div>
  );
};

export default MultipleImages;
