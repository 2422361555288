/* eslint-disable no-restricted-properties */
import {
  Form, Formik, FormikConfig, FormikValues,
} from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../store/Hooks';
import StepsWrapper from './StepsWrapper';

const FormikStepper: React.FC<FormikConfig<FormikValues>> = ({
  children, ...props
}) => {
  const journey = useAppSelector((state) => state.journey);
  const childrenArray = React.Children.toArray(children);
  const [currentChild, setCurrentChild] = useState<React.ReactElement<any> | null>(null);

  useEffect(() => {
    if (journey.currentStep < 0 || journey.currentStep === undefined || !children) {
      return;
    }
    setCurrentChild(childrenArray[journey.currentStep] as React.ReactElement<any>);
  }, [journey.currentStep, children]);

  const isLastStep = () => journey.currentStep === childrenArray.length - 1;

  return (
    <>
      {
        (journey.currentStep !== -1) && <Formik
          {...props}
          enableReinitialize
          initialValues={props.initialValues}
          onSubmit={async (values, helpers) => {
            await props.onSubmit(values, helpers);
            if (isLastStep()) {
              console.log('was last step');
            }
          }}
        >
          <Form>
            <StepsWrapper>
              {currentChild}
            </StepsWrapper>
          </Form>
        </Formik >
      }
    </>
  );
};
export default FormikStepper;
