import { useFormikContext } from 'formik';
import '../Steps.css';
import { useState } from 'react';
import { FaPen, FaPlus } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import FormButton from '../../../components/FormNavButton';
import Spacer from '../../../components/Spacer';
import { ReactComponent as Close } from '../../../assets/img/close-icon.svg';
import Preview from '../../../components/formComponents/Preview';

interface ContainerProps {
  step: number,
  spec: any,
  updateStep: any,
  updateActiveServiceIndex: any
  categories: any,
  services: any
  arrayHelpers: any,
  nextStep: any,
  previousStep: any,
}

const ServicesListStep: React.FC<ContainerProps> = ({
  step,
  spec,
  updateStep,
  updateActiveServiceIndex,
  categories,
  services,
  arrayHelpers,
  nextStep,
  previousStep,
}) => {
  const { submitForm } = useFormikContext();
  const [deleteServiceModalTitle, setDeleteServiceModalTitle] = useState('');
  const [deleteServiceModalDesc, setDeleteServiceModalDesc] = useState('');
  const [serviceToDelete, setServiceToDelete] = useState<number>();
  const [showDeleteServiceModal, setShowDeleteServiceModal] = useState(false);
  const [hasUncategorized, setHasUncategorized] = useState(false);
  const [overLimitAgree, setOverLimitAgree] = useState(false);

  const previousStepChild = async () => {
    previousStep();
    submitForm();
  };

  const nextStepChild = async () => {
    nextStep();
    submitForm();
  };

  const openDeleteServicePopup = (service: any, index: number) => {
    const title = `Are you sure you want to delete the ${service.title} service?`;
    const desc = 'Doing so will permanently deleted it.';
    setDeleteServiceModalTitle(title);
    setDeleteServiceModalDesc(desc);
    setServiceToDelete(index);
    setShowDeleteServiceModal(true);
  };

  const deleteService = () => {
    setShowDeleteServiceModal(false);
    const title = '';
    const desc = '';
    setDeleteServiceModalTitle(title);
    setDeleteServiceModalDesc(desc);
    setServiceToDelete(undefined);
    arrayHelpers.remove(serviceToDelete);
    submitForm();
  };

  const createService = async () => {
    arrayHelpers.push({
      id: uuidv4(),
      completed: false,
      title: '',
      category: '',
      description: '',
      cta: 'Book & Buy Now',
    });
    updateActiveServiceIndex(services.length);
    updateStep(step + 1);
  };

  const changeActiveServiceIndex = async (index: number) => {
    updateActiveServiceIndex(index);
    updateStep(step + 1);
  };

  return (
    <>
      <Modal
        size="lg"
        show={showDeleteServiceModal}
        onHide={() => setShowDeleteServiceModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {deleteServiceModalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {deleteServiceModalDesc}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <FormButton
            onClick={() => setShowDeleteServiceModal(false)}
            style={{ background: 'transparent' }}
            invert
          >
            Close
          </FormButton>
          <FormButton
            onClick={() => deleteService()}
            style={{ background: 'var(--warning)' }}
          >
            Delete
          </FormButton>
        </Modal.Footer>
      </Modal>

      <div className="d--f ai--c">
        <h1 className="standard-form-title c--primary">
          Overview of your services
        </h1>
        <Preview spec={spec} />
      </div>
      <div className="standard-form-wrapper pa-scroll-container bg--white">
        <p className="pa-form-step-description">
            Click on the pen icon to edit any service or click on “add a service”
            to add one if you plan to offer a service that is not in the list.
        </p>
        {categories && categories.map((serviceCategory: any, index: number) => {
          const servicesList = services.find(
            (obj: any) => (obj.category === serviceCategory.name),
          );
          if (servicesList) {
            return (
              <div key={index}>
                <Spacer />
                <h4>{serviceCategory.name}</h4>
                <Spacer height={10} />
                <div className="d--f fd--r fw--w jc--fs ai--c">
                  {
                    services.map((service: any, serviceIndex: number) => {
                      if (service.category === serviceCategory.name) {
                        return (
                          <div
                            key={serviceIndex}
                            className="pa-item-wrapper">
                            <div
                              className="pa-item"
                              onClick={() => changeActiveServiceIndex(serviceIndex)}
                            >
                              {service.title}
                            </div>
                            <div className="pa-item-edit-btn">
                              <button type="submit" onClick={() => changeActiveServiceIndex(serviceIndex)} className="pa-btn icon-only">
                                <FaPen style={{
                                  color: 'var(--light)', height: 15,
                                }} />
                              </button>
                            </div>
                            {(services.length > 1) && <div className="pa-item-delete-btn">
                              <button type="submit" onClick={() => openDeleteServicePopup(service, serviceIndex)} className="pa-btn icon-only bg--warn">
                                <Close style={{
                                  color: 'var(--light)', width: 15, height: 15,
                                }} />
                              </button>
                            </div>}
                          </div>

                        );
                      }
                      return null;
                    })
                  }
                </div>
              </div>
            );
          }
          return (<div key={index}>
            <h4>{serviceCategory.name}</h4>
            <p>No services have been created for this category yet.</p>
          </div>);
        })}
        <div>
          {hasUncategorized && <>
            <Spacer />
            <h4>Uncategorized</h4>
            <p>Services below need to be categorized before moving on to the next step.</p>
            <Spacer height={10} />
          </>}
          <div className="d--f fd--r fw--w jc--fs ai--c">
            {services.map((service: any, serviceIndex: number) => {
              if (!(categories.filter((e: any) => e.name === service.category).length > 0)) {
                if (!hasUncategorized) {
                  setHasUncategorized(true);
                }
                return (
                  <div
                    key={serviceIndex}
                    className="pa-item-wrapper">
                    <div
                      className="pa-item"
                      onClick={() => changeActiveServiceIndex(serviceIndex)}
                    >
                      {service.title}
                    </div>
                    <div className="pa-item-edit-btn">
                      <button type="submit" onClick={() => changeActiveServiceIndex(serviceIndex)} className="pa-btn icon-only">
                        <FaPen style={{
                          color: 'var(--light)', height: 15,
                        }} />
                      </button>
                    </div>
                    {(services.length > 1) && <div className="pa-item-delete-btn">
                      <button type="submit" onClick={() => openDeleteServicePopup(service, serviceIndex)} className="pa-btn icon-only bg--warn">
                        <Close style={{
                          color: 'var(--light)', width: 15, height: 15,
                        }} />
                      </button>
                    </div>}
                  </div>
                );
              }
              return null;
            })
            }
          </div>
        </div>
        <Spacer />
        <button
          onClick={createService}
          className="pa-repeater-button"
          disabled={
            spec.content.limit
                && services.length >= spec.content.limit
                && !overLimitAgree
          }
        >
              Add a service <FaPlus style={{ fontSize: '0.7em', marginLeft: 5, marginBottom: 4 }} />
        </button>
        {spec.content.limit && <span style={{
          fontSize: 14,
          fontWeight: 600,
          marginLeft: 10,
          color: (spec.content.limit > services.length) ? 'var(--success)' : 'var(--danger)',
        }}>
          <span>{services.length}</span>/<span>{spec.content.limit}</span>
        </span>
        }
        { (spec.content.limit
                && services.length >= spec.content.limit)
                && <div>
                  <Spacer />
                  <input
                    className='pa-checkbox'
                    type="checkbox"
                    checked={overLimitAgree}
                    onChange={() => setOverLimitAgree(!overLimitAgree)}
                    style={{ marginRight: 10 }}
                  />
                  <span style={{ fontStyle: 'italic' }}>{spec.content.limitText}</span>
                </div>
        }
      </div>
      <div className='pa-form-step-nav-container'>
        <FormButton
          onClick={() => { previousStepChild(); }}
          back
          style={{
            marginRight: 10,
          }}
        />
        <FormButton
          onClick={() => { nextStepChild(); }}
          title='Save & Next'
          next
        />
      </div>
    </>
  );
};
export default ServicesListStep;
