/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { JourneyStatus } from '../model/journeyStatus';
import { db } from '../firebaseConfig';

class NotificationManagementService {
  async sendStatusChangeNotification(
    companyId:string,
    status:string,
    slack = true,
    discord = true,
  ) {
    if (process.env.REACT_APP_ENV === 'dev') {
      return;
    }

    let slackLive = false;
    const companyDoc = await db.collection('Companies').doc(companyId).get();
    const company = companyDoc.data();

    if (company && company.name) {
      let message = '';
      switch (status) {
        case JourneyStatus.FILLING_EFORM:
          message = `${company.name}: filling their content builder form.`;
          break;
        case JourneyStatus.CONTENT_SUBMITTED:
          message = `[ACTION REQUIRED] ${company.name}: submitted their content.`;
          break;
        case JourneyStatus.READY_FOR_TEMP_SITE:
          message = `[ACTION REQUIRED] ${company.name}: ready for temporary website.`;
          break;
        case 'went_back_to_editing':
          message = `[NOTICE] ${company.name}: went back to editing after pressing the go live button.`;
          break;
        case JourneyStatus.LIVE:
          slackLive = true;
          message = `[ACTION REQUIRED] ${company.name}: website is now live. (${company.siteUrl})`;
          break;
        case JourneyStatus.TEMP_SITE_REVIEW:
          message = `${company.name}: temporary website is under review by customer. (${company.siteUrl})`;
          break;
        case JourneyStatus.WAITING_DNS:
          message = `${company.name}: DNS instructions have been sent.`;
          break;
        case JourneyStatus.CANCELLED:
          message = `${company.name}: form status changed to cancelled.`;
          break;
        default:
          message = '';
          break;
      }

      // Send Slack notifs
      try {
        if (slack && message) {
          const data = {
            text: message,
          };
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const slackResponse = await axios.post(process.env.REACT_APP_SLACK_WEBHOOK_URL!,
            JSON.stringify(data),
            {
              headers: {
                'Content-Type': false,
              },
            });
          console.log(slackResponse);
        }

        if (slackLive && message) {
          const data = {
            text: message,
          };
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const slackLiveResponse = await axios.post(process.env.REACT_APP_SLACK_LIVE_WEBHOOK_URL!,
            JSON.stringify(data),
            {
              headers: {
                'Content-Type': false,
              },
            });
          console.log(slackLiveResponse);
        }
      } catch (e) {
        console.log(e);
      }

      // Send Discord notif
      try {
        if (discord && message) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const discordResponse = await axios.post(process.env.REACT_APP_DISCORD_WEBHOOK_URL!,
            {
              content: message,
            });
          console.log(discordResponse);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}

const notificationManagementService = new NotificationManagementService();
export default notificationManagementService;
