import { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Preview from '../../../components/formComponents/Preview';
import Text from '../../../components/formComponents/Text';
import FormButton from '../../../components/FormNavButton';
import Spacer from '../../../components/Spacer';
import '../Steps.css';

interface ContainerProps {
  journeyStep: any,
  step: number,
  spec: any,
  updateStep: any,
  updateActiveServiceIndex: any
  categories: any,
  services: any
  activeServiceIndex: any
  arrayHelpers: any
}

const ChooseServiceStep: React.FC<ContainerProps> = ({
  step, updateStep, activeServiceIndex, journeyStep, arrayHelpers, spec, services, categories,
}) => {
  const [selectedCat, setSelectedCat] = useState<any>();

  const close = () => {
    if (!services[activeServiceIndex].title) {
      arrayHelpers.remove(activeServiceIndex);
    }
    updateStep(0);
  };

  const previousStep = async () => {
    updateStep(step - 1);
  };

  const nextStep = async () => {
    updateStep(step + 1);
  };

  const toggleActiveCategory = async (name: string, index: number) => {
    setSelectedCat(index);
    const servicesTemp : any = [];
    services.forEach((s:any) => {
      const sTemp = { ...s };
      servicesTemp.push(sTemp);
    });
    const service = servicesTemp[activeServiceIndex];
    service.category = name;
    arrayHelpers.insert(activeServiceIndex, {
      ...service,
    });
    arrayHelpers.remove(activeServiceIndex + 1);
  };

  useEffect(() => {
    if (!services[activeServiceIndex]) {
      updateStep(0);
    } else {
      const catData = services[activeServiceIndex]?.category;
      // eslint-disable-next-line array-callback-return
      categories.map((cat: any, index: number) => {
        if (cat.name === catData) {
          setSelectedCat(index);
        }
      });
    }
  }, []);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      e.returnValue = true;
    };

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, []);

  return (
    <>
      <div className="d--f ai--c">
        <h1 className="standard-form-title c--primary">
          Add or modify a service
        </h1>
        <Preview spec={spec} />
      </div>
      <div className="standard-form-wrapper pa-scroll-container bg--primary c--light ta--c d--f fd--c jc--c">
        <div style={{
          position: 'absolute', top: 0, right: 0, padding: 10, width: 44, cursor: 'pointer',
        }} onClick={close}>
          <FaTimes style={{ opacity: 0.5, fontSize: 20, color: 'var(--light)' }} />
        </div>
        <Spacer height={20} />
        <h3>Service title</h3>
        <p>This title will appear on the home page, the service page and the navigation menu.</p>
        <div>
          <div className="d--f jc--c">
            <Text
              name={`steps[${journeyStep}].services[${activeServiceIndex}].title`}
              type="text"
              invert
            />
          </div>
        </div>
        <div className='d--f fd--c ai--c'>
          <h3>In which category does this service fit in?</h3>
          <div style={{ margin: '30px 0' }}>
            {categories.map((cat: any, index: number) => (
              <div
                className={`pa-item reverse ${selectedCat === index ? ' active' : ''}`}
                key={index}
                onClick={() => toggleActiveCategory(cat.name, index)}
              >{cat.name}</div>
            ))}
          </div>
        </div>
      </div>
      <div className='pa-form-step-nav-container' style={{ justifyContent: 'center' }}>
        <FormButton
          onClick={() => { previousStep(); }}
          back
          style={{
            marginRight: 10,
            background: 'var(--grey)',
            color: 'var(--light)',
          }}
        />
        <FormButton
          onClick={() => { nextStep(); }}
          title='Continue'
          next
        />
      </div>
    </>
  );
};
export default ChooseServiceStep;
