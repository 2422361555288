import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import { db } from '../firebaseConfig';
import StandardForm from './steps/StandardForm';
import SplitStepSplashscreen from './steps/SplitStepSplashscreen';
import FormikStepper from './FormikStepper';
import SplitTextSplashscreen from './steps/SplitTextSplashscreen';
import JourneyCompleted from './steps/JourneyCompleted';
import Services from './steps/services/Services';
import journeyManagementService from '../services/JourneyManagementService';
import TableOfContents from './steps/TableOfContents';
import FullSplashscreen from './steps/FullSplashscreen';
import { updateJourney } from '../store/slices/JourneySlice';

const Journey: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const journey = useAppSelector((state) => state.journey);
  const dispatch = useAppDispatch();
  const [initialData, setInitialData] = useState({}) as any;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let unsubscribe = () => {};

    async function bindToFirestore() {
      const companyDocRef = db.collection('Companies').doc(user.company);

      const companyDoc = await companyDocRef.get();
      if (!companyDoc.exists) return;

      const companyDocData = companyDoc.data();
      if (!companyDocData) return;

      if (!companyDocData.activeFormId) {
        const formId = await journeyManagementService.initializeJourney(
          user.company,
          companyDocData.themeId,
          companyDocData.sector,
        );
        companyDocData.activeFormId = formId;
      }
      const journeyDocRef = companyDocRef.collection('Journeys').doc(companyDocData.activeFormId);
      const journeyDoc = await journeyDocRef.get();
      const journeyDocData = journeyDoc.data();
      if (journeyDocData) {
        setInitialData(journeyDocData.data);
      }

      unsubscribe = companyDocRef.collection('Journeys').doc(companyDocData.activeFormId)
        .onSnapshot(async (doc) => {
          const data = doc.data();
          if (!data) return;
          const specsRef = db.collection('DefaultJourneySpecs').doc(data.formSpecsId);
          const specsDoc = await specsRef.get();
          if (!specsDoc) return;
          const specsDocData = specsDoc.data();
          if (!specsDocData) return;
          dispatch(updateJourney({
            id: data.id,
            specs: specsDocData,
            data: data.data,
            currentStep: data.currentStep,
          }));
        });
    }

    bindToFirestore();

    return unsubscribe;
  }, []);

  return (
    <>
      <Link to='/admin'
        className='link-to-admin'>Back to dashboard</Link>
      { (journey.specs && initialData && user.company && journey.id)
      && <>
        <FormikStepper
          initialValues={initialData}
          onSubmit={async (values) => {
            console.log('saved!');
            await journeyManagementService.saveJourney(values, user.company, journey.id);
          }}>
          {journey.specs.steps && journey.specs.steps.map((step: any, index: any) => {
            switch (step.type) {
              case 'standardForm':
                return (
                  <StandardForm
                    key={index}
                    spec={step}
                  />
                );
              case 'splitStepSplashscreen':
                return (
                  <SplitStepSplashscreen
                    key={index}
                    spec={step}
                  />
                );
              case 'splitTextSplashscreen':
                return (
                  <SplitTextSplashscreen
                    key={index}
                    spec={step}
                  />
                );
              case 'fullSplashscreen':
                return (
                  <FullSplashscreen
                    key={index}
                    spec={step}
                  />
                );
              case 'tableOfContents':
                return (
                  <TableOfContents
                    key={index}
                    spec={step}
                  />
                );
              case 'services':
                return (
                  <Services
                    key={index}
                    spec={step}
                  />
                );
              case 'journeyCompleted':
                return (
                  <JourneyCompleted
                    key={index}
                    spec={step}
                  />
                );
              default:
                return (<div key={index}></div>);
            }
          })}
        </FormikStepper >
      </>
      }
    </>
  );
};
export default Journey;
