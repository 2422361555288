import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import Journey from '../journey/Journey';
import JourneyNotFound from '../journey/JourneyNotFound';
import Companies from '../pages/Companies';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import UserType from '../model/UserType';
import NotFound from '../pages/NotFound';
import Users from '../pages/Users';
import DefaultForms from '../pages/DefaultForms';
import userManagementService from '../services/UserManagementService';
import { updateUser } from '../store/slices/UserSlice';
import { db } from '../firebaseConfig';
import User from '../model/User';
import Company from '../pages/Company';
import AdminTools from '../pages/AdminTools';
import CompaniesBoard from '../pages/CompaniesBoard';

const MainRouter: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const user = useAppSelector((state) => state.user);
  const [busy, setBusy] = useState(true);
  const [searchParams] = useSearchParams();
  const [isAdminDashboard, setIsAdminDashboard] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let unsubscribe = () => {};

    async function initUser() {
      const token = searchParams.get('token');
      if (token) {
        const id = await userManagementService.initUser(token, dispatch);
        if (id !== -1) {
          unsubscribe = db.collection('Users').doc(id).onSnapshot(async (doc) => {
            dispatch(updateUser(doc.data() as User));
          });
        }
        if (busy) {
          setBusy(false);
        }
      } else {
        console.error('No token passed');
      }
    }
    initUser();
    const admin = searchParams.get('admin');
    setIsAdminDashboard(!!(admin));

    return unsubscribe;
  }, []);

  const RequireAdmin = ({ children }: { children: JSX.Element }) => {
    if (user) {
      if (user.type === UserType.ADMIN) {
        return children;
      }
    }
    return <Navigate to="/journey" state={{ from: location }} />;
  };

  return (
    busy
      ? <>loading</>
      : <div className="d--f fg--1" >
        <div className={`container-content bg--lightgrey ${(user && user.type === UserType.ADMIN && isAdminDashboard) ? 'is-admin-dashboard' : ''}`}>
          <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path='admin' element={<Navigate to="/admin/companies" />} />
            <Route path="/admin/users" element={
              <RequireAdmin>
                <Users />
              </RequireAdmin>
            } />
            <Route path="/admin/companies" element={
              <RequireAdmin>
                <Companies />
              </RequireAdmin>
            } />
            <Route path="/admin/companies-board" element={
              <RequireAdmin>
                <CompaniesBoard />
              </RequireAdmin>
            } />
            <Route path="/admin/companies/:id" element={
              <RequireAdmin>
                <Company />
              </RequireAdmin>
            } />
            <Route path="/admin/default-forms" element={
              <RequireAdmin>
                <DefaultForms />
              </RequireAdmin>
            } />
            <Route path="/admin/admin-tools" element={
              <RequireAdmin>
                <AdminTools />
              </RequireAdmin>
            } />
            <Route path="journey" element={<Journey />} />
            <Route path="journey/:formId" element={<Journey />} />
            <Route path="journey/not-found" element={<JourneyNotFound />} />

            <Route path="/" element={
              user && user.type === UserType.ADMIN && isAdminDashboard
                ? <Navigate to="/admin/companies" state={{ from: location }} />
                : <Navigate to="/journey" state={{ from: location }} />
            } />
          </Routes>
        </div>
      </div>
  );
};

export default MainRouter;
