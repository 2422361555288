import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import './FormComponents.css';

interface CheckboxProps {
  label: string;
  fullWidth?:boolean;
}

const Checkbox: React.FC<FieldHookConfig<string> & CheckboxProps> = ({
  label,
  fullWidth,
  ...props
}) => {
  const [field] = useField(props);
  return (
    <div className="pa-field-wrapper" style={{ width: fullWidth ? '100%' : '50%' }}>
      <input className='pa-checkbox' type="checkbox" {...field}/>
      <label className="pa-checkbox-label">{label}</label>
    </div>
  );
};

export default Checkbox;
