import {
  FaDownload,
} from 'react-icons/fa';
import './Pages.css';
import { Link } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useAppSelector } from '../store/Hooks';
import companyManagmentService from '../services/CompanyManagementService';
import SuperAdminNav from '../components/SuperAdminNav';
import Spacer from '../components/Spacer';
import { db } from '../firebaseConfig';

// TODO: globaly cache companies to reduce number of reads
const CompaniesBoard: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const [companies, setCompanies] = useState([]) as any[];

  useEffect(() => {
    const unsubscribe = db.collection('Companies').onSnapshot((companiesSnapshot) => {
      const companiesArr: any[] = [];
      companiesSnapshot.forEach((doc) => {
        companiesArr.push(
          {
            ...doc.data(),
            id: doc.id,
          },
        );
      });
      setCompanies(companiesArr);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      {user.superAdmin && <SuperAdminNav />}
      <Spacer height={40} />
      <div className="p--20">
        <div className="d--f ai--c">
          <h1>Companies board</h1>
          <FaDownload
            onClick={() => companyManagmentService.exportCSV()}
            style={{
              fontSize: 20,
              color: 'var(--primary)',
              marginLeft: 20,
              cursor: 'pointer',
            }} />
        </div>
        <Link to='/admin/companies'>Table view</Link>
        <Spacer />
        <div>
          {companies.map((company: any, key: any) => (
            !company.isDeleted ? <div key={key} className='pa-company-board-item'>
              {company.name}
            </div> : <Fragment key={key}></Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
export default CompaniesBoard;
