import ReactDOM from 'react-dom';
import './assets/css/index.css';
import './assets/css/variables.css';
import './assets/css/fonts.css';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/Store';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
