import {
  FieldArray, FieldHookConfig, useField, useFormikContext,
} from 'formik';
import React, { useEffect, useState } from 'react';
import { FaArrowDown, FaArrowUp, FaPlus } from 'react-icons/fa';
import Text from './Text';
import { ReactComponent as CloseIcon } from '../../assets/img/close-icon.svg';
import SocialMedia from './SocialMedia';
import TextTextarea from './TextTextArea';
import SelectTextTextarea from './SelectTextTextArea';
import Spacer from '../Spacer';
import Tooltip from '../Tooltip';

interface ContainerProps {
  spec: any;
  type: string;
  tooltip?:string;
  fieldValidation?:string;
}

const Repeater: React.FC<FieldHookConfig<string> & ContainerProps> = ({
  spec,
  type,
  tooltip,
  fieldValidation,
  ...props
}) => {
  const [field] = useField(props);
  const [array, setArray] = useState<any[]>([]);
  const [overLimitAgree, setOverLimitAgree] = useState(false);
  const { validateForm } = useFormikContext();
  useEffect(() => {
    const arrayTemp = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < field.value?.length; i++) {
      arrayTemp.push(field.value[i]);
    }
    setArray(arrayTemp);
  }, [field.value]);

  const removeItem = async (arrayHelpers:any, index:number) => {
    arrayHelpers.remove(index);
    const sleep = (ms:number) => new Promise((r) => setTimeout(r, ms));
    await sleep(1);
    validateForm();
  };

  const addItem = async (arrayHelpers:any) => {
    arrayHelpers.push({});
    validateForm();
  };

  const renderRepeaterChildren = (element: any, fieldIndex: number) => {
    switch (type) {
      case 'text':
        return (
          <Text
            key={fieldIndex}
            name={`${field.name}[${fieldIndex}].name`}
            fieldValidation={fieldValidation}
          />
        );
      case 'socialMedia':
        return (
          <SocialMedia
            name={`${field.name}[${fieldIndex}]`}
            fieldValidation={fieldValidation}
          />
        );
      case 'textTextArea':
        return (
          <TextTextarea
            name={`${field.name}[${fieldIndex}]`}
            spec={spec}
            fieldValidation={fieldValidation}
          />
        );
      case 'selectTextTextArea':
        // TODO: remove if not needed
        return (
          <SelectTextTextarea
            name={`${field.name}[${fieldIndex}]`}
            spec={spec}
            options={'socialMedias'}
          />
        );
      default:
        return (<div key={fieldIndex}></div>);
    }
  };

  return (
    <div className='pa-field-wrapper full'>
      <div className='d--f'>
        <h4 style={{
          fontSize: (spec?.fadeLabel) ? '1em' : '1.2em',
          opacity: (spec?.fadeLabel) ? 0.7 : 1,
          fontWeight: (spec?.fadeLabel) ? 400 : 500,
          marginBottom: 15,
        }}>
          {spec?.label}
        </h4>
        {tooltip
          && <Tooltip>{tooltip}</Tooltip>}
      </div>
      <FieldArray name={field.name}
        render={(arrayHelpers) => (
          <div>
            {
              array.map((element, index) => (
                <div className="d--f ai--c pa-repeater-row-wrap" key={index}>
                  {spec.showControls && <div className='pa-repeater-controls'>
                    {(index !== 0)
                    && <FaArrowUp onClick={() => arrayHelpers.move(index, index - 1) } />}
                    {(index !== (array.length - 1))
                    && <FaArrowDown onClick={() => arrayHelpers.move(index, index + 1) } />}
                  </div>}
                  {
                    renderRepeaterChildren(element, index)
                  }
                  {(!spec.minimum || (spec.minimum && index > spec.minimum - 1))
                  && <div
                    className="pa-repeater-row-remove d--f ai--c jc--c"
                    onClick={() => removeItem(arrayHelpers, index)}>
                    <CloseIcon style={{
                      color: 'var(--light)', width: '50%', height: 'auto',
                    }} />
                  </div>
                  }
                </div>
              ))
            }
            <button
              onClick={() => addItem(arrayHelpers)}
              className="pa-repeater-button"
              type="submit"
              disabled={
                spec.limit
                && array.length >= spec.limit
                && !overLimitAgree
              }
            >
              Add <FaPlus style={{ fontSize: '0.7em', marginLeft: 5, marginBottom: 4 }} />
            </button>
            { (spec.limit
                && array.length >= spec.limit)
                && spec.limitText
                && <div>
                  <Spacer />
                  <input
                    className='pa-checkbox'
                    type="checkbox"
                    checked={overLimitAgree}
                    onChange={() => setOverLimitAgree(!overLimitAgree)}
                    style={{ marginRight: 10 }}
                  />
                  <span style={{ fontStyle: 'italic' }}>{spec.limitText}</span>
                </div>
            }
          </div>
        )
        } />
      <Spacer />
    </div >
  );
};

export default Repeater;
