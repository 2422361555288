import { FaTimes } from 'react-icons/fa';
import Preview from '../../../components/formComponents/Preview';
import FormButton from '../../../components/FormNavButton';
import Spacer from '../../../components/Spacer';
import '../Steps.css';

interface ContainerProps {
  step: number,
  spec:any,
  updateStep: any,
  services: any,
  activeServiceIndex: number,
  arrayHelpers: any,
}

const InformationStep: React.FC<ContainerProps> = ({
  step, updateStep, spec, services, activeServiceIndex, arrayHelpers,
}) => {
  const close = () => {
    if (!services[activeServiceIndex].title) {
      arrayHelpers.remove(activeServiceIndex);
    }
    updateStep(0);
  };

  const previousStep = async () => {
    close();
    updateStep(step - 1);
  };

  const nextStep = async () => {
    updateStep(step + 1);
  };

  return (
    <>
      <div className="d--f ai--c">
        <h1 className="standard-form-title c--primary">
          Add or modify a service
        </h1>
        <Preview spec={spec} />
      </div>
      <div className="standard-form-wrapper pa-scroll-container bg--primary d--f fd--c ai--c jc--c">
        <div style={{
          position: 'absolute', top: 0, right: 0, padding: 10, width: 44, cursor: 'pointer',
        }} onClick={close}>
          <FaTimes style={{ opacity: 0.5, fontSize: 20, color: 'var(--light)' }} />
        </div>
        <div style={{
          width: '80%', margin: 'auto', textAlign: 'center', color: 'white',
        }}>
          <p className='p--big'>
              In the next few screens, you will provide information that will
              populate the website page with more information for this service.
              This information will help your customers make an informed decision
              when booking this service on your website.
          </p>
          <Spacer />
        </div>
      </div >
      <div className='pa-form-step-nav-container' style={{ justifyContent: 'center' }}>
        <FormButton
          onClick={() => { previousStep(); }}
          back
          style={{
            marginRight: 10,
            background: 'var(--grey)',
            color: 'var(--light)',
          }}
        />
        <FormButton
          onClick={() => { nextStep(); }}
          title='Continue'
          next
        />
      </div>
    </>
  );
};
export default InformationStep;
