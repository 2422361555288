import { Fragment, useEffect, useState } from 'react';
import {
  FaCaretDown, FaCaretUp, FaDownload, FaFilter, FaListAlt, FaRegTimesCircle, FaUserAlt,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import ReactSelect, { SingleValue } from 'react-select';
import { db } from '../firebaseConfig';
import { JourneyStatus, JourneyStatusOptions } from '../model/journeyStatus';
import companyManagmentService from '../services/CompanyManagementService';
import journeyManagementService from '../services/JourneyManagementService';
import userManagementService from '../services/UserManagementService';
import { mapSectorsToString, mapThemesToString } from '../services/utilities';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import { resetJourneyState } from '../store/slices/JourneySlice';
import Accordion from './Accordion';
import Logs from './Logs';

const CompanyTable: React.FC = () => {
  const [allCompanies, SetAllCompanies] = useState([]) as any[];
  const [companies, setCompanies] = useState([]) as any[];
  const [sortType, setSortType] = useState('timestamp_asc');
  const [searchTerms, setSearchTerms] = useState('');
  const [statusFilter, setStatusFilter] = useState([
    JourneyStatusOptions[0],
    JourneyStatusOptions[1],
    JourneyStatusOptions[2],
    JourneyStatusOptions[3],
    JourneyStatusOptions[4],
    JourneyStatusOptions[5]]) as any[];
  const [daysOutstandingFilter, setDaysOutstandingFilter] = useState([]) as any[];
  const [showFilters, setShowFilters] = useState(false);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const daysFilterOptions = [
    { value: '5', label: '< 5' },
    { value: '10', label: '5 - 15' },
    { value: '20', label: '15 - 25' },
    { value: '30', label: '25 - 35' },
    { value: '40', label: '35 - 45' },
    { value: '45', label: '45 +' },
  ];

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const toggleAndClearFilters = () => {
    clearFilters();
  };

  useEffect(() => {
    const unsubscribe = db.collection('Companies').onSnapshot((companiesSnapshot) => {
      let companiesArr: any[] = [];
      companiesSnapshot.forEach((doc) => {
        companiesArr.push(
          {
            ...doc.data(),
            id: doc.id,
          },
        );
      });
      companiesArr = sortCompanies(companiesArr);
      setCompanies(companiesArr);
      SetAllCompanies(companiesArr);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setCompanies(sortCompanies(companies));
  }, [sortType]);

  useEffect(() => {
    filterResults();
  }, [allCompanies]);

  useEffect(() => {
    filterResults();
  }, [statusFilter, daysOutstandingFilter, searchTerms]);

  const filterResults = async () => {
    let tempCompanies = allCompanies;
    let continueFiltering = true;

    if (searchTerms !== '' && continueFiltering) {
      tempCompanies = tempCompanies.filter(
        (element:any) => element.name.toLowerCase().includes(searchTerms.toLowerCase()),
      );
      continueFiltering = false;
    }

    if (statusFilter.length > 0 && continueFiltering) {
      let statusTempCompanies = [] as any[];
      statusFilter.forEach((filter: any) => {
        const result = tempCompanies.filter(
          (form:any) => form.formStatus === filter.value,
        );
        statusTempCompanies = statusTempCompanies.concat(result);
      });
      tempCompanies = statusTempCompanies;
    }

    if (daysOutstandingFilter.length > 0 && continueFiltering) {
      let outstandingTempCompanies = [] as any[];
      daysOutstandingFilter.forEach((filter: any) => {
        const result = tempCompanies.filter(
          (element:any) => {
            switch (filter.value) {
              case '5':
                return calculateDays(element.timestamp.seconds)
              <= parseInt(filter.value, 10);
              case '45':
                return calculateDays(element.timestamp.seconds)
              >= parseInt(filter.value, 10);
              default:
                return calculateDays(element.timestamp.seconds)
              < parseInt(filter.value, 10) + 5
              && calculateDays(element.timestamp.seconds)
              >= parseInt(filter.value, 10) - 5;
            }
          },
        );
        outstandingTempCompanies = outstandingTempCompanies.concat(result);
      });
      tempCompanies = outstandingTempCompanies;
    }
    setCompanies(tempCompanies);
  };

  const clearFilters = () => {
    setStatusFilter([]);
    setDaysOutstandingFilter([]);
    filterResults();
  };

  const sortCompanies = (arr:any[]) : any[] => {
    if (sortType === 'timestamp_asc') {
      arr.sort(
        (a, b) => ((a.timestamp > b.timestamp) ? -1 : 1),
      );
    } else if (sortType === 'timestamp_desc') {
      arr.sort(
        (a, b) => ((a.timestamp < b.timestamp) ? -1 : 1),
      );
    } else if (sortType === 'name_asc') {
      arr.sort(
        (a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : 1),
      );
    } else if (sortType === 'name_desc') {
      arr.sort(
        (a, b) => ((a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1),
      );
    } else if (sortType === 'status') {
      arr.sort(
        (a, b) => ((
          (a.formStatus && a.formStatus.toLowerCase())
          < (b.formStatus && b.formStatus.toLowerCase())
        ) ? -1 : 1),
      );
    }
    return arr;
  };

  const calculateDays = (
    serverTimestamp: number,
    comparisonDate = new Date().getTime(),
  ) => Math.floor(
    Math.abs(
      (serverTimestamp * 1000 - comparisonDate) / (1000 * 3600 * 24),
    ),
  );

  const goToForm = async (companyId: string, formId: string) => {
    dispatch(resetJourneyState());
    const changedUserCompany = await userManagementService.changeUserCompany(user.id, companyId);
    if (changedUserCompany) {
      navigate(`/journey/${formId}`);
    }
  };

  const changeStatus = (
    companyId:string,
    newStatus:SingleValue<{ value: string; label: string; }>,
  ) => {
    if (newStatus) {
      companyManagmentService.changeStatus(companyId, newStatus.value);
      companyManagmentService.addLog(companyId, user.name, `Manually changed status to: ${newStatus.label}`);
    }
  };

  const getTimestampColor = (timestamp : number) : string => {
    const timestampDate = new Date(timestamp * 1000);
    const dateNow = new Date();
    const timeDiff = dateNow.getTime() - timestampDate.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);
    if (daysDiff > 20) {
      return 'var(--danger)';
    } if (daysDiff > 10) {
      return 'var(--warning)';
    }
    return 'var(--dark)';
  };

  const downloadJourney = async (company: any) => {
    try {
      await journeyManagementService.downloadActiveJourney(company);
    } catch (e: any) {
      console.log(e);
    }
  };

  const renderCompanyTableItemTitle = (company: any) => (
    <div className="pa-company-table-item-title">
      <div style={{ width: '40%' }}>
        <div className='d--f ai--c'>
          <h2 style={{
            fontSize: 20,
            marginBottom: 0,
          }}>{company.name}</h2>
          <FaUserAlt
            onClick={() => navigate(`/admin/companies/${company.id}`)}
            style={{
              cursor: 'pointer',
              fontSize: 19,
              marginLeft: 15,
              color: 'var(--primary)',
            }} />
          {company.activeFormId
          && <>
            <FaListAlt onClick={() => goToForm(company.id, company.activeFormId)}
              style={{
                cursor: 'pointer',
                fontSize: 20,
                marginLeft: 15,
                color: 'var(--primary)',
              }}
            />
            <FaDownload
              onClick={() => downloadJourney(company)}
              style={{
                cursor: 'pointer',
                fontSize: 18,
                marginLeft: 15,
                color: 'var(--primary)',
              }}
            />

          </>
          }
        </div>
        <span className="pa-company-table-item-theme">
          {`${mapThemesToString(company.themeId).replace('-', ' ')} ${mapSectorsToString(company.sector).replace('-', ' ')}`}
        </span>
      </div>
      <div style={{ width: '30%', paddingLeft: 20 }}>
        <ReactSelect
          className="pa-company-table-select"
          styles={{
            singleValue: (provided) => {
              const whiteSpace = 'pre-wrap';
              return { ...provided, whiteSpace };
            },
          }}
          options={JourneyStatusOptions}
          onChange={(option) => changeStatus(company.id, option)}
          value={JourneyStatusOptions.filter((option) => option.value === company.formStatus)}
          isClearable={false}
          isSearchable={false}
        />
      </div>
      <div style={{ width: '25%', paddingLeft: 10 }}>
        {
          company.goLiveDate && company.formStatus === JourneyStatus.LIVE
            ? (
              <div style={{
                color: 'var(--success)',
              }}>
                <div>{`${calculateDays(company.timestamp.seconds, company.goLiveDate.seconds * 1000)} days`}</div>
                <div>
                  Go live: {new Date(company.goLiveDate.seconds * 1000).toLocaleDateString()}
                </div>
              </div>
            )
            : company.timestamp
          && (
            <div style={{
              color: getTimestampColor(company.timestamp.seconds),
            }}>
              <div>{`${calculateDays(company.timestamp.seconds)} days`}</div>
              <div>
                Activation: {new Date(company.timestamp.seconds * 1000).toLocaleDateString()}
              </div>
            </div>
          )
        }
      </div>
    </div>
  );

  return (
    <>
      <div className='d--f ai--c jc--c' style={{ marginBottom: 20 }}>
        <input placeholder="Search company..." className="pa-search-input" type="text" value={searchTerms} onChange={(e) => setSearchTerms(e.target.value)} />
        <FaFilter
          onClick={() => toggleFilters()}
          style={{
            fontSize: 20,
            color: 'var(--primary)',
            marginRight: 20,
            cursor: 'pointer',
          }} />
      </div>
      {showFilters && <div className="pa-filters-container d--f ai--c jc--sb">
        <ReactSelect
          className="pa-company-filters-select"
          styles={{
            singleValue: (provided) => {
              const whiteSpace = 'pre-wrap';
              return { ...provided, whiteSpace };
            },
          }}
          options={JourneyStatusOptions}
          onChange={(option) => ((option) ? setStatusFilter(option) : setStatusFilter([]))}
          defaultValue={[
            JourneyStatusOptions[0],
            JourneyStatusOptions[1],
            JourneyStatusOptions[2],
            JourneyStatusOptions[3],
            JourneyStatusOptions[4],
            JourneyStatusOptions[5]]}
          value={statusFilter}
          isMulti
          placeholder="Filter by status"
          isClearable={false}
          isSearchable={false}
        />
        <ReactSelect
          className="pa-company-filters-select"
          styles={{
            singleValue: (provided) => {
              const whiteSpace = 'pre-wrap';
              return { ...provided, whiteSpace };
            },
          }}
          isMulti
          options={daysFilterOptions}
          onChange={
            (option) => (
              (option) ? setDaysOutstandingFilter(option) : setDaysOutstandingFilter([])
            )}
          placeholder="Filter by days to go live"
          isClearable={false}
          isSearchable={false}
        />
        <div>
          <FaRegTimesCircle className='pa-filter-button' onClick={toggleAndClearFilters} />
        </div>
      </div>}
      <div>
        <div className='pa-company-table-head'>
          <div
            className="pa-company-table-head-title"
            style={{ width: '45%' }}
            onClick={() => {
              if (sortType === 'name_asc') {
                setSortType('name_desc');
              } else if (sortType === 'name_desc') {
                setSortType('name_asc');
              } else {
                setSortType('name_asc');
              }
            }}
          >
              Name
            {sortType === 'name_desc' && <FaCaretDown />}
            {sortType === 'name_asc' && <FaCaretUp />}
          </div>
          <div
            className="pa-company-table-head-title"
            style={{ width: '30%' }}
          >
              Status
          </div>
          <div
            className="pa-company-table-head-title"
            style={{ width: '25%' }}
            onClick={() => {
              if (sortType === 'timestamp_asc') {
                setSortType('timestamp_desc');
              } else if (sortType === 'timestamp_desc') {
                setSortType('timestamp_asc');
              } else {
                setSortType('timestamp_asc');
              }
            }}
          >
              Days to go live
            {sortType === 'timestamp_desc' && <FaCaretDown />}
            {sortType === 'timestamp_asc' && <FaCaretUp />}
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          {companies.map((company: any, key: any) => (
            !company.isDeleted ? <div key={key} className='pa-company-table-item'>
              <Accordion
                title={renderCompanyTableItemTitle(company)}
              >
                <h3 style={{ fontSize: 18 }}>Logs</h3>
                <Logs companyId={company.id} />
              </Accordion>
            </div> : <Fragment key={key}></Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
export default CompanyTable;
