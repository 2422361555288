import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import './FormComponents.css';
import Text from './Text';
import Textarea from './Textarea';

interface ContainerProps {
  label?: string;
  fieldValidation?:string;
  spec: any
}

const TextTextarea: React.FC<FieldHookConfig<string> & ContainerProps> = ({
  label, spec, fieldValidation, ...props
}) => {
  const [field] = useField(props);
  return (
    <div className="pa-selecttexttextarea-wrap">
      {
        label
        && <label className="pa-field-label">{label}</label>
      }
      <Text
        name={`${field.name}.text`}
        label={spec?.textLabel}
        fieldValidation={fieldValidation}
      />
      <Textarea
        name={`${field.name}.textArea`}
        label={spec?.textAreaLabel}
        fieldValidation={fieldValidation}
      />
    </div>
  );
};

export default TextTextarea;
