import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import './FormComponents.css';

interface ContainerProps {
  label?: string;
  big?: boolean;
  fieldValidation?:string;
}

const Textarea: React.FC<FieldHookConfig<string> & ContainerProps> = ({
  label, big, fieldValidation, ...props
}) => {
  const validateTextareaField = async (value:string) => {
    const schema = Yup.string().max(1000, 'Too long');
    let error;
    try {
      await schema.validate(value);
    } catch (err:any) {
      // eslint-disable-next-line prefer-destructuring
      error = err.errors[0];
    }
    return error;
  };

  const allProps = {
    ...props,
    validate: validateTextareaField,
  };
  const [field] = useField(allProps);
  return (
    <div className="pa-field-wrapper" style={props.style}>
      {
        label
        && <label className="pa-field-label">{label}</label>
      }
      <textarea className="pa-textarea" {...field} style={{ height: (big) ? 200 : 100 }} maxLength={1000}/>
      <ErrorMessage name={field.name} render={(msg:string) => <div className="pa-input-error">{msg}</div>} />
    </div>
  );
};

export default Textarea;
