import { useEffect, useState } from 'react';
import { FaLaptopCode } from 'react-icons/fa';
import Spacer from '../../components/Spacer';
import companyManagmentService from '../../services/CompanyManagementService';
import { useAppSelector } from '../../store/Hooks';

const WebsiteIsLive: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const [company, setCompany] = useState(null) as any;

  useEffect(() => {
    if (!user.company) {
      return;
    }
    companyManagmentService.getCompany(user.company).then((c) => {
      setCompany(c);
    });
  }, []);

  return (
    <div className="pa-fullscreen-container completed has-journey-backdrop-graphics pa-scroll-container">
      <div className="pa-fullscreen-content-wrapper">
        <div className='ta--c'>
          <FaLaptopCode style={{
            color: 'var(--light)',
            fontSize: 80,
          }} />
          <Spacer />
          <h2>Your website is now live!</h2>
          <Spacer />
          {company
            && <div className="pa-website-info-wrapper">
              <h3>Website information</h3>
              <div>
                Website url: {company.siteUrl && <a href={company.siteUrl} target="_blank">{company.siteUrl}</a>}
              </div>
              <div>Website login url: {company.siteAdminUrl && <a href={company.siteAdminUrl} target="_blank">
                {company.siteAdminUrl}</a>}</div>
              <div>Website username: {company.WPUser && <span>{company.WPUser}</span>}</div>
              <div>Website password: {company.WPPW && <span>{company.WPPW}</span>}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
export default WebsiteIsLive;
