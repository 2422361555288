import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from '../../model/User';

// Define the initial state using that type
const initialState: User = {
  type: '',
  token: '',
  id: '',
  company: '',
  name: '',
  superAdmin: false,
} as User;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, { payload }: PayloadAction<User>) => ({
      ...state,
      ...payload,
    }),
    updateUserCompany: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      company: payload,
    }),
  },

});

export const {
  updateUser,
  updateUserCompany,
} = userSlice.actions;

export default userSlice;
