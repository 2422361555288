import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import Preview from '../../../components/formComponents/Preview';
import Text from '../../../components/formComponents/Text';
import Textarea from '../../../components/formComponents/Textarea';
import FormButton from '../../../components/FormNavButton';
import Spacer from '../../../components/Spacer';
import '../Steps.css';

interface ContainerProps {
  journeyStep: any,
  step: number,
  spec: any,
  updateStep: any,
  services: any,
  activeServiceIndex: any,
  arrayHelpers: any,
}

const ActionBtnStep: React.FC<ContainerProps> = ({
  step, updateStep, activeServiceIndex, journeyStep, services, spec, arrayHelpers,
}) => {
  const { submitForm } = useFormikContext();
  const [, , helpers] = useField(`steps[${journeyStep}].services[${activeServiceIndex}].completed`);

  const close = () => {
    if (!services[activeServiceIndex].title) {
      arrayHelpers.remove(activeServiceIndex);
    }
    updateStep(0);
  };

  const previousStep = async () => {
    updateStep(step - 1);
  };

  const nextStep = async () => {
    helpers.setValue(true);
    updateStep(0);
    submitForm();
  };

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      e.returnValue = true;
    };

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, []);

  return (
    <>
      <div className="d--f ai--c">
        <h1 className="standard-form-title c--primary">
          Add or modify a service
        </h1>
        <Preview spec={spec} />
      </div>
      <div className="standard-form-wrapper pa-scroll-container bg--primary c--light">
        <div style={{
          position: 'absolute', top: 0, right: 0, padding: 10, width: 44, cursor: 'pointer',
        }} onClick={close}>
          <FaTimes style={{ opacity: 0.5, fontSize: 20, color: 'var(--light)' }} />
        </div>
        <h4 className="ta--c">{services[activeServiceIndex]?.title}</h4>
        <Spacer height={30} />
        <h3>Call to action button</h3>
        <p>
          This is the title of the” button” below the service description that
          lead the online shopper to the online booking page.
          Common titles are “Book Now”, “Pricing & Availability” or “Request a quote”.
        </p>
        <div>
          <div className="d--f">
            <Text
              name={`steps[${journeyStep}].services[${activeServiceIndex}].cta`}
              type="text"
              invert
            />
          </div>
        </div>
        <Spacer height={10} />
        <h3>Booking page embed code</h3>
        <p>
          This is the code used to embed your booking page for this service on your website.
          See <a target="_blank" href="https://support.miocommerce.com/en/articles/4574760-how-do-i-install-a-booking-page-on-my-website" style={{ color: 'white' }}>our guide</a> for help about where to find this embed code.
        </p>
        <Textarea
          style={{ width: '100%' }}
          name={`steps[${journeyStep}].services[${activeServiceIndex}].bookingPageCode`}
        />
      </div>
      <div className='pa-form-step-nav-container' style={{ justifyContent: 'center' }}>
        <FormButton
          onClick={() => { previousStep(); }}
          back
          style={{
            marginRight: 10,
            background: 'var(--grey)',
            color: 'var(--light)',
          }}
        />
        <FormButton
          onClick={() => { nextStep(); }}
          title='Continue'
          next
        />
      </div>
    </>
  );
};
export default ActionBtnStep;
