import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormButton from '../components/FormNavButton';
import { db } from '../firebaseConfig';
import { JourneyStatus } from '../model/journeyStatus';
import JourneyStepsStatus from '../model/JourneyStepsStatus';
import companyManagmentService from '../services/CompanyManagementService';
import journeyManagementService from '../services/JourneyManagementService';
import { StepNavigationContextProvider } from '../services/StepNavigationContext';
import { useAppSelector } from '../store/Hooks';
import WebsiteIsLive from './steps/WebsiteIsLive';

const StepsWrapper: React.FC = ({ children }) => {
  const user = useAppSelector((state) => state.user);
  const journey = useAppSelector((state) => state.journey);
  const [showConfirmationMajor, setShowConfirmationMajor] = useState(false);
  const { values, setFieldValue } : { values : any, setFieldValue : any} = useFormikContext();
  const [formStatus, setFormStatus] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    async function fetchFormStatus() {
      const companyDocRef = db.collection('Companies').doc(user.company);

      const companyDoc = await companyDocRef.get();
      if (!companyDoc.exists) return;

      const companyDocData = companyDoc.data();
      if (!companyDocData) return;

      if (companyDocData.formStatus) {
        setFormStatus(companyDocData.formStatus);
      }
    }
    fetchFormStatus();
  }, []);

  const nextStep = () => {
    if (!journey
      || !journey.specs
      || journey.currentStep < 0
      || journey.currentStep === undefined
    ) return;
    const currentMajorStep = journey.specs.steps[journey.currentStep].major;
    const nextMajorStep = journey.specs.steps[journey.currentStep + 1].major;
    if (currentMajorStep !== nextMajorStep && currentMajorStep > 0) {
      setShowConfirmationMajor(true);
    } else {
      journeyManagementService.nextStep(user.company);
    }
  };

  const previousStep = () => {
    journeyManagementService.previousStep(user.company);
  };

  const nextMajorStep = async () => {
    if (!journey.specs
      || journey.currentStep < 0
      || journey.currentStep === undefined) return;
    setShowConfirmationMajor(false);

    let stepIndex = journey.currentStep;
    const currentMajorStep = values.steps[stepIndex].major;
    while (stepIndex > 0
    && values.steps[stepIndex]
    && values.steps[stepIndex].status
    && values.steps[stepIndex].major === currentMajorStep) {
      if (values.steps[stepIndex].status !== JourneyStepsStatus.ERROR) {
        setFieldValue(
          `steps[${stepIndex}].status`,
          JourneyStepsStatus.APPROVED,
        );
      }
      stepIndex -= 1;
    }
    journeyManagementService.saveJourney(values, user.company, journey.id);
    journeyManagementService.nextStep(user.company);
    toggleFormStatus();
  };

  const skipMajorStep = async () => {
    if (journey.currentStep < 0
      || journey.currentStep === undefined) return;
    setShowConfirmationMajor(false);
    let stepIndex = journey.currentStep;
    const currentMajorStep = values.steps[stepIndex].major;
    while (stepIndex > 0
    && values.steps[stepIndex]
    && values.steps[stepIndex].status
    && values.steps[stepIndex].major === currentMajorStep) {
      if (values.steps[stepIndex].status !== JourneyStepsStatus.ERROR) {
        setFieldValue(
          `steps[${stepIndex}].status`,
          JourneyStepsStatus.VISITED,
        );
      }
      stepIndex -= 1;
    }
    journeyManagementService.saveJourney(values, user.company, journey.id);

    journeyManagementService.nextStep(user.company);
    toggleFormStatus();
  };

  const toggleFormStatus = () => {
    companyManagmentService.changeStatus(user.company, JourneyStatus.FILLING_EFORM, false);
  };

  return (
    <>
      {
        (formStatus === JourneyStatus.LIVE)
          ? <WebsiteIsLive />
          : <div className="pa-form-steps-wrapper">
            <Modal
              size="lg"
              show={showConfirmationMajor}
              onHide={() => setShowConfirmationMajor(false)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
            Are you done with this section?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pa-modal-preview-body" style={{ margin: 0, textAlign: 'center' }}>
                <p>
            By pressing "I approve", you are confirming that the content in the section
            is correct and will be used to build your custom e-commerce website. If you would
            like to make further changes before your website is built, press the "Skip" button.
                </p>
                <div style={{ textAlign: 'center' }}>
                  <FormButton
                    onClick={() => skipMajorStep()}
                    style={{
                      display: 'inline-block',
                      background: 'transparent',
                      border: '2px solid var(--warning)',
                      color: 'var(--warning)',
                      marginRight: 10,
                    }}
                  >
            Skip
                  </FormButton>
                  <FormButton
                    onClick={() => nextMajorStep()}
                    style={{
                      display: 'inline-block',
                      background: 'var(--primary)',
                      border: '2px solid var(--primary)',
                    }}
                  >
            I approve
                  </FormButton>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <FormButton
                  onClick={() => setShowConfirmationMajor(false)}
                  style={{
                    background: 'transparent',
                  }}
                  invert
                >
            Close
                </FormButton>
              </Modal.Footer>
            </Modal>
            <StepNavigationContextProvider value={{
              nextStep,
              previousStep,
            }}>
              {children}
            </StepNavigationContextProvider>
          </div >
      }
    </>
  );
};

export default StepsWrapper;
