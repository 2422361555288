const JourneyStatus = {
  NOT_STARTED: 'Not started',
  FILLING_EFORM: 'Filling eform',
  CONTENT_SUBMITTED: 'Content submitted, waiting for booking pages',
  READY_FOR_TEMP_SITE: 'Ready for temporary site',
  TEMP_SITE_REVIEW: 'Temporary website under review',
  WAITING_DNS: 'DNS instructions have been sent',
  LIVE: 'Website is live',
  CANCELLED: 'Cancelled',
};

const JourneyStatusOptions = [
  { value: JourneyStatus.NOT_STARTED, label: 'Not started' },
  { value: JourneyStatus.FILLING_EFORM, label: 'Filling eform' },
  { value: JourneyStatus.CONTENT_SUBMITTED, label: 'Content submitted, waiting for booking pages' },
  { value: JourneyStatus.READY_FOR_TEMP_SITE, label: 'Ready for temporary site' },
  { value: JourneyStatus.TEMP_SITE_REVIEW, label: 'Temporary website under review' },
  { value: JourneyStatus.WAITING_DNS, label: 'DNS instructions have been sent' },
  { value: JourneyStatus.LIVE, label: 'Website is live' },
  { value: JourneyStatus.CANCELLED, label: 'cancelled' },
];

export {
  JourneyStatus,
  JourneyStatusOptions,
};
