/* eslint-disable quotes */
import {
  FaBroom,
  FaBrush,
  FaCar,
  FaCheck,
  FaCheckSquare,
  FaDesktop,
  FaHandSparkles,
  FaLaptop,
  FaMobile,
  FaPaintRoller,
  FaPhone,
  FaPhoneSquare,
  FaSnowplow,
  FaThumbsUp,
  FaWater,
} from 'react-icons/fa';

import { ReactComponent as OsLivePricing } from '../assets/img/os-live-pricing.svg';

const BookingStepIconType = [
  {
    value: "os-icon-live-pricing",
    label: 'Live pricing',
    icon: <OsLivePricing />,
  },
  {
    value: "<i class='fa-solid fa-broom'></i>",
    label: 'Broom',
    icon: <FaBroom />,
  },
  {
    value: "<i class='fa-solid fa-check'></i>",
    label: 'Check',
    icon: <FaCheck />,
  },
  {
    value: "<i class='fa-solid fa-square-check'></i>",
    label: 'Check square',
    icon: <FaCheckSquare />,
  },
  {
    value: "<i class='fa-solid fa-desktop'></i>",
    label: 'Computer',
    icon: <FaDesktop />,
  },
  {
    value: "<i class='fa-solid fa-laptop'></i>",
    label: 'Laptop',
    icon: <FaLaptop />,
  },
  {
    value: "<i class='fas fa-phone'></i>",
    label: 'Phone',
    icon: <FaPhone />,
  },
  {
    value: "<i class='fa-solid fa-phone-square'></i>",
    label: 'Phone square',
    icon: <FaPhoneSquare />,
  },
  {
    value: "<i class='fa-solid fa-mobile'></i>",
    label: 'Mobile phone',
    icon: <FaMobile />,
  },
  {
    value: "<i class='fa-solid fa-snowplow'></i>",
    label: 'Snowplow',
    icon: <FaSnowplow />,
  },
  {
    value: "<i class='fa-solid fa-hand-sparkles'></i>",
    label: 'Clean',
    icon: <FaHandSparkles />,
  },
  {
    value: "<i class='fa-solid fa-water'></i>",
    label: 'Water',
    icon: <FaWater />,
  },
  {
    value: "<i class='fa-solid fa-paint-roller'></i>",
    label: 'Paint roller',
    icon: <FaPaintRoller />,
  },
  {
    value: "<i class='fa-solid fa-brush'></i>",
    label: 'Paint brush',
    icon: <FaBrush />,
  },
  {
    value: "<i class='fa-solid fa-thumbs-up'></i>",
    label: 'Thumbs up',
    icon: <FaThumbsUp />,
  },
  {
    value: "<i class='fa-solid fa-car'></i>",
    label: 'Car',
    icon: <FaCar />,
  },
];

export default BookingStepIconType;
