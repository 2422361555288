import axios from 'axios';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import './FormComponents.css';

interface ContainerProps {
  label?: string;
}

const AddressField: React.FC<FieldHookConfig<string> & ContainerProps> = ({
  label, ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [validationText, setValidationText] = useState('');
  const [success, setSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleOnBlur = async (value: string) => {
    if (!value) {
      setValidationText('This field is required');
      setSuccess(false);
      setIsDisabled(false);
      return;
    }

    setValidationText('Verifying your address...');
    setIsDisabled(true);

    const axiosRes = await axios.get('https://maps.googleapis.com/maps/api/geocode/json',
      {
        params: {
          address: value,
          key: process.env.REACT_APP_API_KEY,
        },

      });

    if (!axiosRes.data
        || axiosRes.data.status !== 'OK'
        || !axiosRes.data.results[0]
        || !axiosRes.data.results[0].geometry
        || !axiosRes.data.results[0].geometry.location
        || !axiosRes.data.results[0].geometry.location.lat
        || !axiosRes.data.results[0].geometry.location.lng) {
      setValidationText('Could not find your address, make sure it is spelled correctly before going to the next step');
      setSuccess(false);
      setIsDisabled(false);
      return;
    }

    setValidationText('Address is valid!');
    setSuccess(true);

    const newFieldPath = field.name.split('.');
    newFieldPath.pop();
    const newFieldPathString = newFieldPath.join('').concat('.latlong');

    setFieldValue(
      newFieldPathString,
      {
        lat: axiosRes.data.results[0].geometry.location.lat,
        lng: axiosRes.data.results[0].geometry.location.lng,
      },
    );

    setIsDisabled(false);
  };

  return (
    <div className="pa-field-wrapper">
      {
        label
        && <label className="pa-field-label">{label}</label>
      }
      <textarea
        className="pa-textarea" {...field} style={{ height: 100 }}
        maxLength={1000}
        onBlur={(e) => { handleOnBlur(e.target.value); }}
        disabled={isDisabled}
      />
      {
        validationText
        && <p className="pa-input-error" style={{
          color: success ? 'var(--success)' : 'var(--danger)',
        }}>{validationText}</p>
      }
    </div>
  );
};

export default AddressField;
