import { Field } from 'formik';
import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import BookingStepIconType from '../../model/BookingStepIconType';
import socialMediasType from '../../model/SocialMediasType';
import companyManagmentService from '../../services/CompanyManagementService';
import { useAppSelector } from '../../store/Hooks';
import Spacer from '../Spacer';
import './FormComponents.css';

interface ContainerProps {
  label: string;
  options: string;
  name: string;
}

const SelectField: React.FC<any> = ({
  options,
  field,
  form,
}) => (
  <ReactSelect
    options={options}
    name={field.name}
    value={options ? options.find((option: any) => option.value === field.value) : ''}
    onChange={(option) => form.setFieldValue(field.name, option.value)}
    onBlur={field.onBlur}
    defaultValue={options[0]}
    isClearable={false}
    isSearchable={true}
    formatOptionLabel={(e) => {
      if (e.icon) {
        return (<div style={{ display: 'flex', alignItems: 'center' }}>
          {e.icon}
          <span style={{ marginLeft: 5, fontSize: 15 }}>{e.label}</span>
        </div>);
      }
      return (<div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginLeft: 5, fontSize: 15 }}>{e.label}</span>
      </div>);
    }}
  />
);

const Select: React.FC<ContainerProps> = ({
  label, options, name,
}) => {
  const user = useAppSelector((state) => state.user);
  const journey = useAppSelector((state) => state.journey);
  const [optionsList, setOptionsList] = useState<any>([]);
  useEffect(() => {
    switch (options) {
      case 'services':
        companyManagmentService.getAllServices(journey.id, user.company)
          .then((response: any) => {
            console.log(response);
            setOptionsList(response);
          });
        break;
      case 'socialMedias':
        setOptionsList(socialMediasType);
        break;
      case 'stepIcons':
        setOptionsList(BookingStepIconType);
        break;
      default:
        break;
    }
  }, []);

  return (
    <div className="pa-field-wrapper" style={{ width: '50%', paddingRight: 20 }}>
      <label className="pa-field-label">{label}</label>
      <Spacer height={5} />
      <Field name={name} component={SelectField} options={optionsList} />
    </div>
  );
};

export default Select;
