import { useState } from 'react';

interface InlineTextInputProps {
  defaultValue: string;
  updateValueFunction: (e:any, value:string) => void;
}

const InlineTextInput: React.FC<InlineTextInputProps> = ({
  defaultValue,
  updateValueFunction,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [wasChanged, setWasChanged] = useState(false);

  const submitChange = (e:any, v:string) => {
    setWasChanged(false);
    updateValueFunction(e, v);
  };

  const handleChange = (v:string) => {
    setWasChanged(true);
    setValue(v);
  };

  return (
    <form onSubmit={(e) => submitChange(e, value)} className="pa-company-info-edit">
      <input
        type="text"
        placeholder=""
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        className="pa-company-info-edit-input"
      />
      <input type="submit" value="save" className='pa-company-info-edit-btn' disabled={!wasChanged} />
    </form>
  );
};
export default InlineTextInput;
