import { Field, useField, useFormikContext } from 'formik';
import '../Steps.css';
import { useEffect } from 'react';
import RichText from '../../../components/formComponents/RichText';
import Text from '../../../components/formComponents/Text';
import FormButton from '../../../components/FormNavButton';
import SingleImage from '../../../components/formComponents/SingleImage';
import ImageType from '../../../model/ImageType';
import MultipleImages from '../../../components/formComponents/MultipleImages';
import Spacer from '../../../components/Spacer';
import Preview from '../../../components/formComponents/Preview';

interface ContainerProps {
  journeyStep: any,
  step: number,
  spec: any,
  updateStep: any,
  services: any
  activeServiceIndex: any
}

const SummaryStep: React.FC<ContainerProps> = ({
  step, updateStep, activeServiceIndex, journeyStep, services, spec,
}) => {
  const { submitForm } = useFormikContext();
  const [, , helpers] = useField(`steps[${journeyStep}].services[${activeServiceIndex}].completed`);

  const previousStep = async () => {
    updateStep(step - 1);
  };

  const nextStep = async () => {
    helpers.setValue(true);
    updateStep(0);
    submitForm();
  };

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      e.returnValue = true;
    };

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, []);

  return (
    <>
      <div className="d--f ai--c">
        <h1 className="standard-form-title c--primary">
            Reviewing the service : {services[activeServiceIndex]?.title}
        </h1>
        <Preview spec={spec} />
      </div>
      <div className="standard-form-wrapper pa-scroll-container bg--grey c--light">
        <div className="d--f">
          <Text
            name={`steps[${journeyStep}].services[${activeServiceIndex}].title`}
            type="text"
            label='Service title'
          />
        </div>
        <label className="pa-field-label" style={{ marginBottom: 5 }}>Service description</label>
        <Field component={RichText} name={`steps[${journeyStep}].services[${activeServiceIndex}].description`} />
        <div className="d--f">
          <Text
            name={`steps[${journeyStep}].services[${activeServiceIndex}].cta`}
            type="text"
            label='Service action button text'
          />
        </div>
        <SingleImage
          name={`steps[${journeyStep}].services[${activeServiceIndex}].mainImage`}
          label='Main service image'
          imageType={ImageType.SERVICE_MAIN_IMAGE}
          service={services[activeServiceIndex]}
        />
        <MultipleImages
          name={`steps[${journeyStep}].services[${activeServiceIndex}].images`}
          label='Additional service images'
          imageType={ImageType.SERVICE_IMAGES}
          service={services[activeServiceIndex]}
        />
        <Spacer />
        <div className='pa-form-step-nav-container' style={{ justifyContent: 'center' }}>
          <FormButton
            onClick={() => { previousStep(); }}
            back
            style={{
              marginRight: 10,
              background: 'var(--light-grey)',
            }}
          />
          <FormButton
            onClick={() => { nextStep(); }}
            title='Confirm'
            next
          />
        </div>
      </div>
    </>
  );
};
export default SummaryStep;
