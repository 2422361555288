import '../Steps.css';
import { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import FormButton from '../../../components/FormNavButton';
import SingleImage from '../../../components/formComponents/SingleImage';
import ImageType from '../../../model/ImageType';
import Spacer from '../../../components/Spacer';
import MultipleImages from '../../../components/formComponents/MultipleImages';
import Preview from '../../../components/formComponents/Preview';

interface ContainerProps {
  journeyStep: any,
  step: number,
  spec: any,
  updateStep: any,
  services: any,
  activeServiceIndex: any,
  arrayHelpers: any,
}

const MainImage: React.FC<ContainerProps> = ({
  step, updateStep, activeServiceIndex, journeyStep, services, spec, arrayHelpers,
}) => {
  const close = () => {
    if (!services[activeServiceIndex].title) {
      arrayHelpers.remove(activeServiceIndex);
    }
    updateStep(0);
  };
  const previousStep = async () => {
    updateStep(step - 1);
  };

  const nextStep = async () => {
    updateStep(step + 1);
  };

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      e.returnValue = true;
    };

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, []);

  return (
    <>
      <div className="d--f ai--c">
        <h1 className="standard-form-title c--primary">
          Add or modify a service
        </h1>
        <Preview spec={spec} />
      </div>
      <div className="standard-form-wrapper pa-scroll-container bg--primary c--light">
        <div style={{
          position: 'absolute', top: 0, right: 0, padding: 10, width: 44, cursor: 'pointer',
        }} onClick={close}>
          <FaTimes style={{ opacity: 0.5, fontSize: 20, color: 'var(--light)' }} />
        </div>
        <h4 className="ta--c">{services[activeServiceIndex]?.title}</h4>
        <Spacer height={20} />
        <h3>Upload a picture that best represents this service</h3>
        <p>
            This image will be the main image your customers will see when
            looking at your services on your website.
        </p>
        <SingleImage
          name={`steps[${journeyStep}].services[${activeServiceIndex}].mainImage`}
          label='Main service image'
          imageType={ImageType.SERVICE_MAIN_IMAGE}
          service={services[activeServiceIndex]}
        />
        <Spacer />
        <h3>Upload more pictures describing this service</h3>
        <p>
            This is optional. These additionnal pictures will help
            describe this service in more details.
        </p>
        <MultipleImages
          name={`steps[${journeyStep}].services[${activeServiceIndex}].images`}
          label='Additional service images'
          imageType={ImageType.SERVICE_IMAGES}
          service={services[activeServiceIndex]}
        />
        <Spacer />
      </div>
      <div className='pa-form-step-nav-container' style={{ justifyContent: 'center' }}>
        <FormButton
          onClick={() => { previousStep(); }}
          back
          style={{
            marginRight: 10,
            background: 'var(--grey)',
            color: 'var(--light)',
          }}
        />
        <FormButton
          onClick={() => { nextStep(); }}
          title='Continue'
          next
        />
      </div>
    </>
  );
};
export default MainImage;
