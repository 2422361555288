import Editor from 'react-simple-code-editor';
import { useEffect, useState } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism-tomorrow.css';
import { db } from '../firebaseConfig';
import Spacer from '../components/Spacer';
import SuperAdminNav from '../components/SuperAdminNav';
import { useAppSelector } from '../store/Hooks';

const DefaultForms: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const [journeyDataJSONToEdit, setJourneyDataJSONToEdit] = useState('');
  const [journeyDataJSONCurrentlyEditing, setJourneyDataJSONCurrentlyEditing] = useState('');
  const [journeyDataJSONS, setJourneyDataJSONS] = useState([] as any);
  const [journeySpecsJSONToEdit, setJourneySpecsJSONToEdit] = useState('');
  const [journeySpecsJSONCurrentlyEditing, setJourneySpecsJSONCurrentlyEditing] = useState('');
  const [journeySpecsJSONS, setJourneySpecsJSONS] = useState([] as any);

  useEffect(() => {
    const unsubscribeData = db.collection('DefaultJourneyData').onSnapshot((journeyDataSnapshot) => {
      const dataArr : any[] = [];
      journeyDataSnapshot.forEach((doc) => {
        dataArr.push(
          {
            ...doc.data(),
            id: doc.id,
          },
        );
      });
      setJourneyDataJSONS(dataArr);
    });

    const unsubscribeSpecs = db.collection('DefaultJourneySpecs').onSnapshot((journeySpecsSnapshot) => {
      const specsArr : any[] = [];
      journeySpecsSnapshot.forEach((doc) => {
        specsArr.push(
          {
            ...doc.data(),
            id: doc.id,
          },
        );
      });
      setJourneySpecsJSONS(specsArr);
    });

    return () => {
      unsubscribeData();
      unsubscribeSpecs();
    };
  }, []);

  useEffect(() => {
    if (!journeyDataJSONToEdit) {
      setJourneyDataJSONCurrentlyEditing('');
      return;
    }
    journeyDataJSONS.forEach((element:any) => {
      if (element.id === journeyDataJSONToEdit) {
        setJourneyDataJSONCurrentlyEditing(JSON.stringify(element, null, 2));
      }
    });
  }, [journeyDataJSONToEdit]);

  useEffect(() => {
    if (!journeySpecsJSONToEdit) {
      setJourneySpecsJSONCurrentlyEditing('');
      return;
    }
    journeySpecsJSONS.forEach((element:any) => {
      if (element.id === journeySpecsJSONToEdit) {
        setJourneySpecsJSONCurrentlyEditing(JSON.stringify(element, null, 2));
      }
    });
  }, [journeySpecsJSONToEdit]);

  const saveData = () => {
    if (journeyDataJSONToEdit) {
      db.collection('DefaultJourneyData').doc(journeyDataJSONToEdit).update(
        JSON.parse(journeyDataJSONCurrentlyEditing),
      );
    }
  };

  const saveSpecs = () => {
    if (journeySpecsJSONToEdit) {
      db.collection('DefaultJourneySpecs').doc(journeySpecsJSONToEdit).update(
        JSON.parse(journeySpecsJSONCurrentlyEditing),
      );
    }
  };

  return (
    <div className="p--20">
      {user.superAdmin && <SuperAdminNav />}
      <h1>Default forms</h1>
      <h2>Form data</h2>
      <select
        value={journeyDataJSONToEdit}
        onChange={(e) => setJourneyDataJSONToEdit(e.target.value)}
      >
        <option value="">--</option>
        {journeyDataJSONS.map((journeyData : any, key : any) => (
          <option key={key} value={journeyData.id}>{journeyData.id}</option>
        ))}
      </select>
      <button onClick={saveData}>Save</button>
      <Editor
        value={journeyDataJSONCurrentlyEditing}
        onValueChange={(code) => setJourneyDataJSONCurrentlyEditing(code)}
        highlight={(code) => Prism.highlight(code, Prism.languages.json, 'json')}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          width: '100%',
          background: '#2d2d2d',
          caretColor: 'white',
        }}
      />
      <Spacer />
      <h2>Form specs</h2>
      <select
        value={journeySpecsJSONToEdit}
        onChange={(e) => setJourneySpecsJSONToEdit(e.target.value)}
      >
        <option value="">--</option>
        {journeySpecsJSONS.map((journeySpecs : any, key : any) => (
          <option key={key} value={journeySpecs.id}>{journeySpecs.id}</option>
        ))}
      </select>
      <button onClick={saveSpecs}>Save</button>
      <Editor
        value={journeySpecsJSONCurrentlyEditing}
        onValueChange={(code) => setJourneySpecsJSONCurrentlyEditing(code)}
        highlight={(code) => Prism.highlight(code, Prism.languages.json, 'json')}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          width: '100%',
          background: '#2d2d2d',
          caretColor: 'white',
        }}
      />
    </div>
  );
};
export default DefaultForms;
