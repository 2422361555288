import Papa from 'papaparse';
import firebase from 'firebase';
import SuperAdminNav from '../components/SuperAdminNav';
import { JourneyStatus } from '../model/journeyStatus';
import { useAppSelector } from '../store/Hooks';
import companyManagmentService from '../services/CompanyManagementService';
import { db } from '../firebaseConfig';
import journeyManagementService from '../services/JourneyManagementService';

const AdminTools: React.FC = () => {
  const user = useAppSelector((state) => state.user);

  const changeHandler = (event:any) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        handleParse(results.data);
      },
    });
  };

  const handleParse = async (data:any) => {
    console.log(data);
    return;
    const dataGood : any[] = [];

    await Promise.all(data.map(async (element : any) => {
      const el = element;
      el.Template = mapTemplate(el.Template);
      el.Industry = mapIndustry(el.Industry);
      el.Status = mapStatus(el.Status);
      el.ActivationDate = firebase.firestore.Timestamp.fromDate(new Date(el.ActivationDate));
      if (el.LaunchDate) {
        el.LaunchDate = firebase.firestore.Timestamp.fromDate(new Date(el.LaunchDate));
      }
      if (el.CompanyGuid) {
        el.CompanyGuid = el.CompanyGuid.replace(/\s/g, '');
        dataGood.push(el);
      }
    }));

    console.log(dataGood);

    dataGood.forEach(async (element : any) => {
      try {
        // create company
        console.log(element.CompanyGuid);
        const company = await companyManagmentService.getCompany(element.CompanyGuid);
        // eslint-disable-next-line no-constant-condition
        if (!company && element.CompanyGuid) {
          companyManagmentService.createCompany(
            element.CompanyGuid,
            element.CompanyName,
            element.Industry,
            element.Template,
          );

          // initialize form
          await journeyManagementService.initializeJourney(
            element.CompanyGuid, element.Template, element.Industry,
          );

          let siteUrl = '';
          if (element.SiteUrl) {
            siteUrl = element.SiteUrl;
          } else if (element.TempUrl) {
            siteUrl = element.TempUrl;
          }
          // update info
          const companyDocRef = db.collection('Companies').doc(element.CompanyGuid);
          await companyDocRef.update({
            formStatus: (element.Status) ? element.Status : '',
            WPPW: (element.Password) ? element.Password : '',
            WPUser: (element.Username) ? element.Username : '',
            goLiveDate: (element.LaunchDate)
              ? element.LaunchDate : firebase.firestore.Timestamp.fromDate(new Date()),
            siteAdminUrl: (siteUrl) ? `${siteUrl}wp-admin` : '',
            siteUrl,
            timestamp: (element.ActivationDate)
              ? element.ActivationDate : firebase.firestore.Timestamp.fromDate(new Date()),
          });
          console.log(`Created company: ${element.CompanyName}`);
        }
        if (company) {
          console.log(`Not created company: ${element.CompanyName}, company exists`);
        } else {
          console.log(`Not created company: ${element.CompanyName}`);
        }
      } catch (e) {
        console.error(e);
      }
    });
  };

  const mapTemplate = (t:string) : string => {
    switch (t) {
      case 'Ace-P':
        return 'b0fe03ee-ec6d-4c85-b300-57db4e721965';
      case 'Ace':
        return '6d697ad9-a7cb-4d04-8449-0b9695d52e29';
      case 'Acl-P':
        return '509bdb13-65fc-4982-8c53-f330934b4b87';
      case 'Acl':
        return 'df42ab1d-d3b6-4de0-b531-c7d42757d5f4';
      case 'Tri-P':
        return 'd4c2df60-c5bc-465b-9bbc-48ef6921037c';
      case 'Tri':
        return '9e8b73bf-c174-4147-acb7-e3700d172795';
      default:
        return 'general';
    }
  };

  const mapIndustry = (i:string) : number => {
    switch (i) {
      case 'HC&CC':
      case 'Home Cleaning':
      case 'Commercial Cleaning':
        return 1;
      case 'Mobile Car Wash/Detailing':
        return 4;
      default:
        return -1;
    }
  };

  const mapStatus = (s:string) : string => {
    switch (s) {
      case 'Client cancelled':
        return JourneyStatus.CANCELLED;
      case '7 - Website Launched':
        return JourneyStatus.LIVE;
      case 'Website launch postponed':
        return JourneyStatus.NOT_STARTED;
      case '2 - Scripts not yet submitted':
        return JourneyStatus.NOT_STARTED;
      case '4 - website not yet approved':
        return JourneyStatus.NOT_STARTED;
      case '5 - DNS not yet updated':
        return JourneyStatus.NOT_STARTED;
      case '1 - Content not yet submitted':
        return JourneyStatus.NOT_STARTED;
      default:
        return JourneyStatus.NOT_STARTED;
    }
  };

  return (
    <div className="p--20">
      {user.superAdmin && <SuperAdminNav />}

      <h1>Admin tools</h1>
      <p>Import customers</p>
      <input
        type="file"
        name="file"
        accept=".csv"
        onChange={changeHandler}
        style={{ display: 'block', margin: '10px auto' }}
      />
    </div>
  );
};
export default AdminTools;
