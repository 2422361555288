/* eslint-disable quotes */
import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitter,
  FaGoogle,
  FaPinterest,
  FaTiktok,
  FaYoutube,
  FaVimeo,
  FaYelp,
  FaQuestion,
  FaLinkedin,
} from 'react-icons/fa';

const socialMediasType = [
  {
    value: "<i class='fab fa-facebook-f'></i>",
    label: 'Facebook',
    icon: <FaFacebookSquare />,
  },
  {
    value: "<i class='fab fa-linkedin'></i>",
    label: 'LinkedIn',
    icon: <FaLinkedin />,
  },
  {
    value: "<i class='fab fa-instagram'></i>",
    label: 'Instagram',
    icon: <FaInstagram />,
  },
  {
    value: "<i class='fab fa-twitter'></i>",
    label: 'Twitter',
    icon: <FaTwitter />,
  },
  {
    value: "<i class='fab fa-google'></i>",
    label: 'Google',
    icon: <FaGoogle />,
  },
  {
    value: "<i class='fab fa-pinterest'></i>",
    label: 'Pinterest',
    icon: <FaPinterest />,
  },
  {
    value: "<i class='fab fa-tiktok'></i>",
    label: 'TikTok',
    icon: <FaTiktok />,
  },
  {
    value: "<i class='fab fa-youtube'></i>",
    label: 'Youtube',
    icon: <FaYoutube />,
  },
  {
    value: "<i class='fab fa-vimeo'></i>",
    label: 'Vimeo',
    icon: <FaVimeo />,
  },
  {
    value: "<i class='fab fa-yelp'></i>",
    label: 'Yelp',
    icon: <FaYelp />,
  },
  {
    value: "<i class='fas fa-question'></i>",
    label: 'Other',
    icon: <FaQuestion />,
  },
];

export default socialMediasType;
