/* eslint-disable class-methods-use-this */
import axios from 'axios';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { db } from '../firebaseConfig';
import User from '../model/User';
import UserType from '../model/UserType';
import { updateUser } from '../store/slices/UserSlice';
import companyManagmentService from './CompanyManagementService';

class UserManagementService {
  async getUserInfoMioCommerce(token: string, companyId: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const axiosRes = await axios.get(process.env.REACT_APP_PODIUMIO_API_BASE!, {
      params: {
        guid: companyId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'X-BL-CLOUD-API-KEY': `${process.env.REACT_APP_X_BL_CLOUD_API_KEY}`,
        'Content-Type': 'application/json, text/plain, */*',
      },
    });

    if (!axiosRes.data) {
      throw new Error('Unable to fetch user data from MioCommerce');
    }

    return axiosRes.data;
  }

  async initUser(token: string, dispatch: any, dev = false) {
    // allow dev to continue with existing user
    if (dev) {
      const existingUserDoc = await db.collection('Users').doc(token).get();
      const existingUser = existingUserDoc.data();
      if (existingUser) {
        console.log('[DEV] User exists, initializing now...');
        dispatch(updateUser(existingUser as User));
      }
      return token;
    }

    const decodedToken: any = jwt_decode(token);
    if (!decodedToken.uid) {
      console.log(decodedToken);
      return -1;
    }

    try {
      const miocommerceUser = await this.getUserInfoMioCommerce(token, decodedToken.org);
      const company = await companyManagmentService.getCompany(decodedToken.org);
      if (!company) {
        companyManagmentService.createCompany(
          decodedToken.org,
          miocommerceUser.company_name,
          miocommerceUser.sector,
          miocommerceUser.theme_guid,
        );
      }
      const userDoc = await db.collection('Users').doc(decodedToken.uid).get();
      if (userDoc && userDoc.exists) {
        await userDoc.ref.update({
          id: decodedToken.uid,
          name: decodedToken.name,
          type: (decodedToken.role.split('|').find((e: string) => e === 'Admin')) ? UserType.ADMIN : UserType.OPERATOR,
          company: decodedToken.org,
        });
      } else {
        await userDoc.ref.set({
          id: decodedToken.uid,
          name: decodedToken.name,
          type: (decodedToken.role.split('|').find((e: string) => e === 'Admin')) ? UserType.ADMIN : UserType.OPERATOR,
          company: decodedToken.org,
        }, { merge: true });
      }
      const userDocData = await userDoc.ref.get();
      dispatch(updateUser(userDocData.data() as User));
    } catch (e) {
      console.error(e);
    }
    return decodedToken.uid;
  }

  async changeUserCompany(userId: string, companyId: string) {
    try {
      const userDoc = db.collection('Users').doc(userId);
      await userDoc.update({
        company: companyId,
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}

const userManagementService = new UserManagementService();
export default userManagementService;
