import { useFormikContext } from 'formik';
import { useContext, useEffect } from 'react';
import FormButton from '../../components/FormNavButton';
import Spacer from '../../components/Spacer';
import JourneyStepsStatus from '../../model/JourneyStepsStatus';
import StepNavigationContext from '../../services/StepNavigationContext';
import { useAppSelector } from '../../store/Hooks';

interface ContainerProps {
  spec: any,
}
const FullSplashscreen: React.FC<ContainerProps> = ({
  spec,
}) => {
  const { values, submitForm, setFieldValue }
  : {values:any, submitForm:any, setFieldValue:any} = useFormikContext();
  const journey = useAppSelector((state) => state.journey);
  const { nextStep, previousStep } = useContext(StepNavigationContext);

  const previousStepChild = async () => {
    previousStep();
    submitForm();
  };

  const nextStepChild = () => {
    nextStep();
    submitForm();
  };

  useEffect(() => {
    if (values.steps[journey.currentStep]
      && values.steps[journey.currentStep].status
      && values.steps[journey.currentStep].status === JourneyStepsStatus.UNSEEN) {
      changeStepStatus(JourneyStepsStatus.VISITED);
    }
  }, []);

  const changeStepStatus = async (status:string) => {
    setFieldValue(
      `steps[${journey.currentStep}].status`,
      status,
    );
  };

  return (
    <div className="pa-fullscreen-container has-journey-backdrop-graphics pa-scroll-container">
      <div className="pa-fullscreen-content-wrapper ta--c">
        <h1 style={{ fontSize: '1.6em' }}>{spec.title}</h1>
        <Spacer />
        <div dangerouslySetInnerHTML={{ __html: spec.content.description }} />
        <div className='pa-fullscreen-nav-btn-container'>
          <FormButton
            onClick={() => { previousStepChild(); }}
            back
          />
          <FormButton
            onClick={() => { nextStepChild(); }}
            title={spec.content.nextBtnText}
            invert
            next
          />
        </div>
      </div>
    </div>
  );
};
export default FullSplashscreen;
