import './assets/css/utilities.css';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './routing/MainRouter';
import 'bootstrap/dist/css/bootstrap.min.css';

const App: React.FC = () => (
  <div className="pa-app-container c--dark bg--light d--f fd--c">
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  </div>
);
export default App;
