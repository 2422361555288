import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaListAlt, FaLongArrowAltLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import ReactSelect, { SingleValue } from 'react-select';
import InlineTextInput from '../components/inlineTextInput';
import Logs from '../components/Logs';
import Spacer from '../components/Spacer';
import { db } from '../firebaseConfig';
import { JourneyStatusOptions } from '../model/journeyStatus';
import companyManagmentService from '../services/CompanyManagementService';
import userManagementService from '../services/UserManagementService';
import { mapSectorsToString, mapThemesToString } from '../services/utilities';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import { resetJourneyState } from '../store/slices/JourneySlice';

const Company: React.FC = () => {
  const { id } = useParams();
  const [company, setCompany] = useState(null) as any;
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [showDeleteCompanyConf, setShowDeleteCompanyConf] = useState(false);
  const handleCloseDeleteCompanyConf = () => setShowDeleteCompanyConf(false);
  const handleShowDeleteCompanyConf = () => setShowDeleteCompanyConf(true);

  const handleDeleteCompany = () => {
    db.collection('Companies').doc(id).update({
      isDeleted: true,
    });
    handleCloseDeleteCompanyConf();
  };

  // TODO: move repeated functions to service

  useEffect(() => {
    if (!id) {
      return;
    }
    companyManagmentService.getCompany(id).then((c) => {
      setCompany(c);
    });
  }, []);

  const goToForm = async (companyId: string, formId: string) => {
    dispatch(resetJourneyState());
    const changedUserCompany = await userManagementService.changeUserCompany(user.id, companyId);
    if (changedUserCompany) {
      navigate(`/journey/${formId}`);
    }
  };

  const changeStatus = (
    companyId:string,
    newStatus:SingleValue<{ value: string; label: string; }>,
  ) => {
    if (newStatus) {
      companyManagmentService.changeStatus(companyId, newStatus.value);
      companyManagmentService.addLog(companyId, user.name, `Manually changed status to: ${newStatus.label}`);
    }
  };

  const getTimestampColor = (timestamp : number) : string => {
    const timestampDate = new Date(timestamp * 1000);
    const dateNow = new Date();
    const timeDiff = dateNow.getTime() - timestampDate.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);
    if (daysDiff > 20) {
      return 'var(--danger)';
    } if (daysDiff > 10) {
      return 'var(--warning)';
    }
    return 'var(--dark)';
  };

  const calculateDays = (serverTimestamp: number) => Math.floor(
    Math.abs(
      (serverTimestamp * 1000 - new Date().getTime()) / (1000 * 3600 * 24),
    ),
  );

  const editSiteUrl = (e:any, value:string) => {
    e.preventDefault();
    db.collection('Companies').doc(id).update({
      siteUrl: value,
    });
  };

  const editSiteAdminUrl = (e:any, value:string) => {
    e.preventDefault();
    db.collection('Companies').doc(id).update({
      siteAdminUrl: value,
    });
  };

  const editWPUser = (e:any, value:string) => {
    e.preventDefault();
    db.collection('Companies').doc(id).update({
      WPUser: value,
    });
  };

  const editWPPW = (e:any, value:string) => {
    e.preventDefault();
    db.collection('Companies').doc(id).update({
      WPPW: value,
    });
  };

  return (
    <div>
      <Spacer height={40} />
      <FaLongArrowAltLeft onClick={() => navigate('/admin/companies')}
        style={{
          cursor: 'pointer',
          fontSize: 22,
          marginLeft: 20,
          color: 'var(--primary)',
        }} />
      {company && <div className="p--20">
        <div className="d--f ai--c">
          <h1>{company.name}</h1>
          {company.activeFormId
          && <FaListAlt onClick={() => goToForm(company.id, company.activeFormId)}
            style={{
              cursor: 'pointer',
              fontSize: 20,
              marginLeft: 15,
              color: 'var(--primary)',
            }} />}
        </div>
        <span className="pa-company-table-item-theme">
          {`${mapThemesToString(company.themeId).replace('-', ' ')} ${mapSectorsToString(company.sector).replace('-', ' ')}`}
        </span>
        <Spacer height={40} />
        <div className="d--f jc--sb">
          <div className="pa-company-page-section">
            <h3>Website information</h3>
            <div>
                Website url:&nbsp;
              <InlineTextInput
                defaultValue={company.siteUrl ? company.siteUrl : ''}
                updateValueFunction={editSiteUrl}
              />
            </div>
            <div>
              Website login url:&nbsp;
              <InlineTextInput
                defaultValue={company.siteAdminUrl ? company.siteAdminUrl : ''}
                updateValueFunction={editSiteAdminUrl}
              />
            </div>
            <div>
              Website username:&nbsp;
              <InlineTextInput
                defaultValue={company.WPUser ? company.WPUser : ''}
                updateValueFunction={editWPUser}
              />
            </div>
            <div>
              Website password:&nbsp;
              <InlineTextInput
                defaultValue={company.WPPW ? company.WPPW : ''}
                updateValueFunction={editWPPW}
              />
            </div>
          </div>
          <div className="pa-company-page-section">
            <div className='d--f jc--sb'>
              <h3>Form status</h3>
              {
                company.timestamp
          && (
            <div style={{
              color: getTimestampColor(company.timestamp.seconds),
            }}>
              <div>{`${calculateDays(company.timestamp.seconds)} days ago`}</div>
              <div>{new Date(company.timestamp.seconds * 1000).toLocaleDateString()}</div>
            </div>
          )
              }
            </div>
            <div style={{
              maxWidth: 500,
            }}>
              <ReactSelect
                className="pa-company-table-select"
                styles={{
                  singleValue: (provided) => {
                    const whiteSpace = 'pre-wrap';
                    return { ...provided, whiteSpace };
                  },
                }}
                options={JourneyStatusOptions}
                onChange={(option) => changeStatus(company.id, option)}
                value={JourneyStatusOptions.filter((option) => option.value === company.formStatus)}
                isClearable={false}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        <div className="pa-company-page-section">
          <h3>Logs</h3>
          <Logs companyId={company.id} />
        </div>
        <Spacer height={5} />
        <div className="pa-company-page-section" style={{ border: '2px solid var(--danger)' }}>
          <h3>Danger zone</h3>
          <Spacer height={10} />
          {user.superAdmin && <div className='pa-company-table-logs-content'>
            <pre>
              {JSON.stringify(company, null, 2)}
            </pre>
          </div>}
          <Spacer height={10} />
          <Button variant="danger" onClick={handleShowDeleteCompanyConf}>Delete company</Button>
        </div>
      </div>}
      <Modal show={showDeleteCompanyConf} onHide={handleCloseDeleteCompanyConf}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this company?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteCompanyConf}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDeleteCompany}>
            Delete company
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Company;
