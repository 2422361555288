import { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { useAppSelector } from '../store/Hooks';

const Users: React.FC = () => {
  const [users, setUsers] = useState([]) as any[];

  useEffect(() => {
    const unsubscribe = db.collection('Users').onSnapshot((usersSnapshot) => {
      const usersArr: any[] = [];
      usersSnapshot.forEach((doc) => {
        usersArr.push(
          {
            ...doc.data(),
          },
        );
      });
      setUsers(usersArr);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="p--20">
      <h1>Users</h1>
      <p>Current User Type : {useAppSelector((state) => state.user.type)}</p>
      {users.map((user: any, key: any) => (
        <div key={key}>
          {user.name}
        </div>
      ))}
    </div>
  );
};
export default Users;
