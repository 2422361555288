import { useField } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState, convertToRaw, ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import React, { useEffect, useState } from 'react';
import './FormComponents.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RichText: React.FC = ({
  ...props
}) => {
  const { field } = props as any;
  const [, , helpers] = useField(field.name);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(),
  );

  useEffect(() => {
    if (field.value) {
      const contentBlock = htmlToDraft(field.value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const eState = EditorState.createWithContent(contentState);
        setEditorState(eState);
      }
    }
  }, []);

  const changeValue = (e: any) => {
    setEditorState(e);
    helpers.setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())).replace('’', '\''));
  };

  return (
    <div >
      <Editor
        editorState={editorState}
        onEditorStateChange={(e: any) => changeValue(e)}
        handlePastedText={(val) => {
          const textLength = editorState.getCurrentContent().getPlainText().length;
          return ((val.length + textLength) >= 6000);
        }}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            className: 'pa-richtext-blocktype-dropdown',
          },
        }}
        wrapperClassName="pa-richtext-wrapper"
        editorClassName="pa-richtext-editor"
        toolbarClassName="pa-richtext-toolbar"
      />
    </div>
  );
};

export default RichText;
